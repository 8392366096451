import styled from 'styled-components';

export const CofirmBoxStyled = styled.div`
  .con_box {
    min-width: 300px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    color: ${({ theme }) => theme.COLORS.BLACK_2};
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    padding: 10px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .btn_zone {
      width: 100%;
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      .btn_box {
        width: 45%;
      }
    }
  }
  .theme_standard {

  }
`;
