import React from 'react';
import { RewardSortStyled } from './styled';
import { Draggable } from 'react-drag-reorder';
import { RenderCommaMoney } from 'utils/functions/FormatMoney';
import { portalService } from 'apiServices';
import { REWARD_RENDER_TYPE } from 'utils/functions/reward';
import { Buttons, Displays } from 'components';
import { toast } from 'react-toastify';

class RewardSort extends React.Component {
  state = { isLoading: true };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let res = await portalService.GET_REWARD_LIST(
      '?pagination=false&sort=sequence'
    );
    if (res && res.status === 200) {
      this.setState({
        isLoading: false,
        data: res.data.docs,
      });
    }
  };

  handleChangedPosition = async (currentPos, newPos) => {
    const { data } = this.state;
    let tempData = data;
    let element = tempData[currentPos];
    tempData.splice(currentPos, 1);
    tempData.splice(newPos, 0, element);
    this.setState({
      dataSortTemp: tempData,
    });
  };

  handleSubmit = async () => {
    const { onUpdated } = this.props;
    const { dataSortTemp } = this.state;
    this.setState({
      isBgLoading: true,
    });
    let resArr = await Promise.all(
      dataSortTemp.map((e, i) => {
        return new Promise(async (resolve) => {
          let params = {
            sequence: i + 1,
          };
          let res = await portalService.PUT_REWARD_SEQUENCE_UPDATE(
            e._id,
            params
          );
          if (res && res.status === 200) {
            resolve(res);
          }
        });
      })
    );
    if (resArr) {
      this.setState({
        isBgLoading: false,
      });
      toast.success('Update success');
      onUpdated && onUpdated();
    }
  };

  render() {
    const { isLoading, isBgLoading, data } = this.state;
    return (
      <RewardSortStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {isLoading ? (
          <div className="loading_wrap">
            <Displays.Loading />
          </div>
        ) : (
          <>
            <div className="rs_action_row">
              <div className="rs_btn_wrap">
                <Buttons.BgStandard
                  label={'Save'}
                  onClick={this.handleSubmit}
                />
              </div>
            </div>
            {data && (
              <Draggable onPosChange={this.handleChangedPosition}>
                {data.map((e, i) => (
                  <div key={i} className="item_wrap">
                    <div className="ps_label">{`${e.name} (${REWARD_RENDER_TYPE(
                      e.type
                    )})`}</div>
                    <div className="ps_value">
                      {RenderCommaMoney(e.redeem_point)} แต้ม
                    </div>
                  </div>
                ))}
              </Draggable>
            )}
          </>
        )}
      </RewardSortStyled>
    );
  }
}

export default RewardSort;

// const mapStateToProps = (state) => ({
//   authenRedux: state.authenRedux,
//   companySelectedRedux: state.companySelectedRedux,
// });

// const mapDispatchToProps = (dispatch) => ({});

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withRouter(RewardSort)));
