import React from "react";
import { UserProfileContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import { portalService } from "apiServices";
import * as XLSX from "xlsx";
import moment from "moment";
import "moment-timezone";
import { AlertControl } from "components/Modals/Alert";
import { toast } from "react-toastify";
import { MdHistory } from "react-icons/md";
import { UserPointHistoryContainer } from "./Modals";
import { Displays, Modals, Inputs, Tables } from "components";

class UserProfileContainer extends React.Component {
  state = { isLoading: true, pagination: null, filterQuery: null, perPage: 10 };

  componentDidMount() {
    const { perPage } = this.state;
    scrollToTopPageFN();
    this.fetchData(`?limit=${perPage}`);
  }

  fetchData = async (stringQuery) => {
    let res = await portalService.GET_CUSTOMER_LIST(stringQuery);
    if (res && res.status === 200) {
      this.setState({
        data: res.data.docs,
        isLoading: false,
        isBgLoading: false,
        pagination: {
          last_page: res.data.totalPages,
          current_page: res.data.page,
        },
      });
    }
  };

  handleFilterKeyword = (e) => {
    const { perPage } = this.state;
    this.setState({
      filterQuery: e,
    });
    this.handleJoinQuery(e, perPage, 1);
  };

  handlePageClick = (e) => {
    const { filterQuery, perPage } = this.state;
    this.handleJoinQuery(filterQuery, perPage, e.selected + 1);
  };

  handleJoinQuery = (query, per_page, page) => {
    let joinQuery = query
      ? `${query}&limit=${per_page}&page=${page}`
      : `?limit=${per_page}&page=${page}`;
    this.setState({
      isLoading: true,
    });
    this.fetchData(joinQuery);
  };

  handleExport = async () => {
    const { filterQuery } = this.state;
    this.setState({
      isBgLoading: true,
    });
    let joinQuery = filterQuery
      ? `${filterQuery}&pagination=false`
      : `?pagination=false`;
    let res = await portalService.GET_CUSTOMER_LIST(joinQuery);
    if (res && res.status === 200) {
      var wb = XLSX.utils.book_new();
      var ws_name = "SheetJS";
      /* make worksheet */
      var ws_data = res.data.docs.reduce((result, e, i) => {
        result.push([
          i + 1,
          `${e.firstname} ${e.lastname}`,
          e.phone_number,
          e.address,
          moment(e.created_at).tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm:ss"),
          e.cu_id,
          e.email,
          e.user_id,
          e.wallet_detail?.point,
        ]);
        return result;
      }, []);
      ws_data.splice(0, 0, [
        "No",
        "Name",
        "Tel",
        "Address",
        "Created at",
        "CU code",
        "Email",
        "User ID",
        "Point",
      ]);
      var ws = XLSX.utils.aoa_to_sheet(ws_data);
      var wscols = [
        { wch: 5 },
        { wch: 35 },
        { wch: 15 },
        { wch: 100 },
        { wch: 25 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      ws["!cols"] = wscols;
      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(
        wb,
        `Customer report ${moment()
          .tz("Asia/Bangkok")
          .format("DD/MM/YYYY")}.xlsx`
      );
      this.setState({
        isBgLoading: false,
      });
    }
  };

  handleDelete = (e) => {
    AlertControl.show({
      title: "ยืนยันการลบ",
      description: "คุณต้องการจะลบใช่หรือไม่",
      btnLabel1: "ยกเลิก",
      btnLabel2: "ยืนยัน",
      onEvent2: async () => {
        this.setState({ isBgLoading: true });
        let resArr = await Promise.all(
          e.map((f) => {
            return new Promise(async (resolve) => {
              let res = await portalService.DELETE_CUSTOMER(f._id);
              if (res && res.status === 200) {
                resolve(res);
              }
            });
          })
        );
        if (resArr) {
          this.setState({
            isBgLoading: false,
          });
          this.fetchData();
          toast.success("ลบสำเร็จ");
        }
      },
    });
  };

  handleClickPointHistory = (e) => {
    this.setState({
      renderModal: (
        <UserPointHistoryContainer
          cusID={e._id}
          point={e?.wallet_detail?.point}
        />
      ),
    });
  };

  handleCloseModal = () => {
    this.setState({
      renderModal: false,
    });
  };

  render() {
    const { isLoading, isBgLoading, pagination, data, renderModal } =
      this.state;
    return (
      <UserProfileContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        <div className="filter_layout">
          <Inputs.InputFilter
            theme_standard_input_filter
            dataFilter={dataFilter}
            btnLabel="ค้นหา"
            onQueryFilter={this.handleFilterKeyword}
            onExport={this.handleExport}
          />
        </div>
        {isLoading ? (
          <Displays.Loading theme_standard_loading heightLayout="336px" />
        ) : (
          <Tables.Standard
            isSelect
            columns={columns({
              handleClickPointHistory: this.handleClickPointHistory,
            })}
            data={data}
            showPagination
            pagination={pagination}
            onDelete={this.handleDelete}
            handlePageClick={this.handlePageClick}
          />
        )}
        <Modals.BodyEmpty
          isShowModal={renderModal}
          onCloseModal={this.handleCloseModal}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </UserProfileContainerStyled>
    );
  }
}

const dataFilter = [
  {
    accessor: "user_id",
    length: "311px",
    filter: {
      fieldQuery: "user_id",
      type: "text",
      label: "Select User : ",
      placeholder: "ค้นหา User",
    },
  },
  {
    accessor: "date",
    filter: {
      fieldQuery: "date",
      type: "dateRank",
      label: "Date Range : ",
    },
  },
];

const columns = ({ handleClickPointHistory }) => [
  {
    Header: <div style={{ textAlign: "left" }}>Name</div>,
    accessor: "fistname",
    Cell: (props) => (
      <div>
        {`${props.row.original.firstname} ${props.row.original.lastname}`}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Tel</div>,
    accessor: "phone_number",
  },
  {
    Header: <div style={{ textAlign: "left" }}>Address</div>,
    accessor: "address",
  },
  {
    Header: <div style={{ textAlign: "left" }}>Created at</div>,
    accessor: "created_at",
    Cell: (props) =>
      moment(props.value).tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm:ss"),
  },
  {
    Header: <div style={{ textAlign: "left" }}>CU code</div>,
    accessor: "cu_id",
  },
  {
    Header: <div style={{ textAlign: "left" }}>Email</div>,
    accessor: "email",
  },
  {
    Header: <div style={{ textAlign: "left" }}>User ID</div>,
    accessor: "user_id",
    Cell: (props) => <div style={{ textAlign: "left" }}>{props.value}</div>,
  },
  {
    Header: <div style={{ textAlign: "left" }}>Point</div>,
    accessor: "wallet_detail",
    Cell: (props) => (
      <div style={{ textAlign: "left" }}>{props.value?.point}</div>
    ),
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (props) => (
      <div
        style={{ textAlign: "right" }}
        onClick={() => handleClickPointHistory(props.row.original)}
      >
        <MdHistory size={24} />
      </div>
    ),
  },
];

export default withRouter(UserProfileContainer);
