import styled from 'styled-components';

export const RadioStyled = styled.div`
  display: flex;
  .label {
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.COLORS.BLACK_2};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
  }
  .radio_input_item {
    margin-bottom: 8px;
    .group_show_radio {
      display: flex;
      align-items: center;
      column-gap: 8px;
      .radio_input {
        margin: 0;
        cursor: pointer;
      }
      .radio_label {
        color: ${({ theme }) => theme.COLORS.BLACK_2};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
        cursor: pointer;
      }
    }
    .radio_sub_detial {
      margin-left: 21px;
      color: ${({ theme }) => theme.COLORS.BLACK_2};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
    }
  }
  .errors {
    margin-top: 2px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }

  .theme_standard {
    display: flex;
    column-gap: 11px;
  }

  input[type="radio"] {
    /* remove standard background appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom radiobutton appearance */
    // display: inline-block;
    width: 17.5px;
    height: 17.5px;
    // padding: 2.5px;
    /* background-color only for content */
    background-clip: content-box;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_3};
    background-color: ${({ theme }) => theme.COLORS.WHITE_1};
    border-radius: 50%;
  }
  
  /* appearance for checked radiobutton */
  input[type="radio"]:checked {
    background-color: ${({ theme }) => theme.COLORS.BLUE_4};
    padding: 2.5px;
  }
  
  /* optional styles, I'm using this for centering radiobuttons */
  .flex {
    display: flex;
    align-items: center;
  }
`;
