import React from "react";
import { RedeemPointContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { Displays, Inputs, Tables } from "components";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import { portalService } from "apiServices";
import * as XLSX from "xlsx";
import moment from "moment";
import "moment-timezone";
import { RenderCommaMoney } from "utils/functions/FormatMoney";

class RedeemPointContainer extends React.Component {
  state = { isLoading: true, pagination: null, filterQuery: null, perPage: 10 };

  componentDidMount() {
    const { perPage } = this.state;

    scrollToTopPageFN();
    this.fetchData(`?limit=${perPage}&status=2`);
  }

  fetchData = async (stringQuery) => {
    let res = await portalService.GET_REWARD_HISTORY_LIST(stringQuery);
    if (res && res.status === 200) {
      this.setState({
        data: res.data.docs,
        isLoading: false,
        pagination: {
          last_page: res.data.totalPages,
          current_page: res.data.page,
        },
      });
    }
  };

  handleFilterKeyword = (e) => {
    const { perPage } = this.state;
    this.setState({
      filterQuery: e,
    });
    this.handleJoinQuery(e, perPage, 1);
  };

  handlePageClick = (e) => {
    const { filterQuery, perPage } = this.state;
    this.handleJoinQuery(filterQuery, perPage, e.selected + 1);
  };

  handleJoinQuery = (query, per_page, page) => {
    let joinQuery = query
      ? `${query}&limit=${per_page}&page=${page}&status=2`
      : `?limit=${per_page}&page=${page}&status=2`;
    this.setState({
      isLoading: true,
    });
    this.fetchData(joinQuery);
  };

  renderRewardType = (e) => {
    switch (e) {
      case "1":
        return "E-coupon";
      case "2":
        return "Coupon";
      case "3":
        return "Other";
      default:
        break;
    }
  };

  handleExport = async () => {
    const { filterQuery } = this.state;
    this.setState({
      isBgLoading: true,
    });
    let joinQuery = filterQuery
      ? `${filterQuery}&pagination=false&status=2`
      : `?pagination=false&status=2`;
    let res = await portalService.GET_REWARD_HISTORY_LIST(joinQuery);
    if (res && res.status === 200) {
      var wb = XLSX.utils.book_new();
      var ws_name = "SheetJS";
      /* make worksheet */
      var ws_data = res.data.docs.reduce((result, e, i) => {
        result.push([
          i + 1,
          e.customer_user_id,
          moment(e.created_at).tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm:ss"),
          e.customer_cu_id,
          this.renderRewardType(e.reward_type),
          e.reward_code,
          e.reward_name,
          moment(e.reward_expire).tz("Asia/Bangkok").format("DD/MM/YYYY"),
          RenderCommaMoney(Number(e.reward_redeem_amount), true),
          RenderCommaMoney(Number(e.reward_cost), true),
          RenderCommaMoney(Number(e.reward_redeem_point), true),
          e.name_shipping,
          e.phone_shipping,
          e.address_shipping,
        ]);
        return result;
      }, []);
      ws_data.splice(0, 0, [
        "No",
        "Login ID",
        "Created at",
        "CU code",
        "Redeem type",
        "Coupon code",
        "Coupon name",
        "Expired date",
        "Qty",
        "Coupon cost",
        "Redeem point",
        "Receiver Name",
        "Tel",
        "Address",
        // 'Total received point',
        // 'Remaining point',
      ]);
      var ws = XLSX.utils.aoa_to_sheet(ws_data);
      var wscols = [
        { wch: 5 },
        { wch: 35 },
        { wch: 25 },
        { wch: 15 },
        { wch: 15 },
        { wch: 25 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      ws["!cols"] = wscols;
      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(
        wb,
        `Redeem point report ${moment()
          .tz("Asia/Bangkok")
          .format("DD/MM/YYYY")}.xlsx`
      );
      this.setState({
        isBgLoading: false,
      });
    }
  };

  render() {
    const { isLoading, isBgLoading, pagination, data } = this.state;

    return (
      <RedeemPointContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        <div className="filter_layout">
          <Inputs.InputFilter
            theme_standard_input_filter
            dataFilter={dataFilter}
            btnLabel="ค้นหา"
            onQueryFilter={this.handleFilterKeyword}
            onExport={this.handleExport}
          />
        </div>
        {isLoading ? (
          <Displays.Loading theme_standard_loading heightLayout="470px" />
        ) : (
          <Tables.Standard
            columns={columns}
            data={data}
            showPagination
            pagination={pagination}
            handlePageClick={this.handlePageClick}
          />
        )}
      </RedeemPointContainerStyled>
    );
  }
}

const dataFilter = [
  {
    accessor: "customer_user_id",
    length: "311px",
    filter: {
      fieldQuery: "customer_user_id",
      type: "text",
      label: "Select User : ",
      placeholder: "User ID",
    },
  },
  {
    accessor: "reward_type",
    length: "311px",
    filter: {
      fieldQuery: "reward_type",
      type: "dropdown",
      options: [
        {
          label: "All",
          value: "0",
        },
        {
          label: "E-coupon",
          value: "1",
        },
        {
          label: "Coupon",
          value: "2",
        },
        {
          label: "Other",
          value: "3",
        },
      ],
      placeholder: "Redeem type",
      label: "Redeem type : ",
    },
  },
  {
    accessor: "reward_name",
    length: "311px",
    filter: {
      fieldQuery: "reward_name",
      type: "text",
      label: "Coupon name : ",
      placeholder: "Coupon name",
    },
  },
  {
    accessor: "date",
    filter: {
      fieldQuery: "date",
      type: "dateRank",
      label: "Date Range : ",
    },
  },
];

const columns = [
  {
    Header: <div style={{ textAlign: "left" }}>Login ID</div>,
    accessor: "customer_user_id",
  },
  {
    Header: <div style={{ textAlign: "left" }}>Created at</div>,
    accessor: "created_at",
    Cell: (props) =>
      moment(props.value).tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm:ss"),
  },
  {
    Header: <div style={{ textAlign: "left" }}>CU code</div>,
    accessor: "customer_cu_id",
  },
  {
    Header: <div style={{ textAlign: "left" }}>Redeem type</div>,
    accessor: "reward_type",
    Cell: (props) => {
      switch (props.value) {
        case "1":
          return "E-coupon";
        case "2":
          return "Coupon";
        case "3":
          return "Other";
        default:
          break;
      }
    },
  },
  {
    Header: <div style={{ textAlign: "left" }}>Coupon code</div>,
    accessor: "reward_code",
  },
  {
    Header: <div style={{ textAlign: "left" }}>Coupon name</div>,
    accessor: "reward_name",
  },
  {
    Header: <div style={{ textAlign: "left" }}>Expired date</div>,
    accessor: "reward_expire",
    Cell: (props) => (
      <div>{moment(props.value).tz("Asia/Bangkok").format("DD/MM/YYYY")}</div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Qty</div>,
    accessor: "reward_redeem_amount",
    Cell: (props) => (
      <div style={{ textAlign: "right" }}>
        {RenderCommaMoney(Number(props.value), true)}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Coupon cost</div>,
    accessor: "reward_cost",
    Cell: (props) => (
      <div style={{ textAlign: "right" }}>
        {RenderCommaMoney(Number(props.value), true)}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "right" }}>Redeem point</div>,
    accessor: "reward_redeem_point",
    Cell: (props) => (
      <div style={{ textAlign: "right" }}>
        {RenderCommaMoney(Number(props.value), true)}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Receiver Name</div>,
    accessor: "name_shipping",
  },
  {
    Header: <div style={{ textAlign: "left" }}>Tel</div>,
    accessor: "phone_shipping",
  },
  {
    Header: <div style={{ textAlign: "left" }}>Address</div>,
    accessor: "address_shipping",
  },
  // {
  //   Header: <div style={{ textAlign: 'right' }}>Total received point</div>,
  //   accessor: 'total_received_point',
  //   Cell: (props) => (
  //     <div style={{ textAlign: 'right' }}>
  //       {RenderCommaMoney(Number(props.value), true)}
  //     </div>
  //   ),
  // },

  // {
  //   Header: <div style={{ textAlign: 'right' }}>Remaining point</div>,
  //   accessor: 'remaining_point',
  //   Cell: (props) => (
  //     <div style={{ textAlign: 'right' }}>
  //       {RenderCommaMoney(Number(props.value), true)}
  //     </div>
  //   ),
  // },
];

export default withRouter(RedeemPointContainer);
