import React from 'react';
import { RewardAddEditContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { Forms, Displays, Modals } from 'components';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';
import { portalService } from 'apiServices';
import { toast } from 'react-toastify';

class RewardAddEditContainer extends React.Component {
  state = {
    isLoading: true,
    labelSuccess:
      this.props.router.params.id === 'create' ? 'สร้างสำเร็จ' : 'แก้ไขสำเร็จ',
    labelError:
      this.props.router.params.id === 'create'
        ? 'สร้างไม่สำเร็จ'
        : 'แก้ไขไม่สำเร็จ',
  };

  componentDidMount() {
    scrollToTopPageFN();
    if (this.props.router.params.id !== 'create') {
      this.fetchData();
    } else {
      setTimeout(() => {
        this.setState({
          isLoading: false,
        });
      }, 1000);
    }
  }

  fetchData = async () => {
    let res = await portalService.GET_REWARD_BY_ID(this.props.router.params.id);
    if (res && res.status === 200) {
      this.setState({
        initialValues: res.data,
        isLoading: false,
      });
    }
  };

  handleCloseModal = () => {
    this.setState({
      renderModal: null,
    });
  };

  deleteAction = async () => {
    this.setState({
      isBgLoading: true,
    });
    let res = await portalService.DELETE_REWARD(this.props.router.params.id);
    if (res && res.status === 200) {
      toast.success('ลบสำเร็จ');
      this.handleNextStep();
    } else {
      toast.error('ลบไม่สำรเร็จ');
    }
  };

  handleSubmit = async (values) => {
    const { labelSuccess, labelError } = this.state;
    this.setState({
      isBgLoading: true,
    });
    let params = {
      ...values,
    };
    delete params.img_path;
    let res =
      this.props.router.params.id === 'create'
        ? await portalService.POST_REWARD(params)
        : await portalService.PUT_REWARD(this.props.router.params.id, params);
    if (res && res.status === 200) {
      let filesTemp = [];
      if (values.img_path_file) {
        filesTemp = [
          ...filesTemp,
          ...values.img_path_file.filter((e) => e.isNewUpload),
        ];
      }
      if (values.reward_img_path_file) {
        filesTemp = [
          ...filesTemp,
          ...values.reward_img_path_file.filter((e) => e.isNewUpload),
        ];
      }
      if (filesTemp.length === 0) {
        toast.success(labelSuccess);
        this.handleNextStep();
      } else {
        this.handleUploadContentMedia(res.data._id, filesTemp);
      }
    } else {
      toast.error(labelError);
    }
  };

  handleUploadContentMedia = (campaignID, files) => {
    Promise.all(
      files.map((e) => {
        return new Promise(async (resolve) => {
          if (e.isNewUpload) {
            const bodyFormData = new FormData();
            bodyFormData.set('reward_id', campaignID);
            bodyFormData.set('type', e.fieldName);
            bodyFormData.append('image', e);
            let res = await portalService.POST_UPLOAD_REWARD(bodyFormData);
            if (res && res.status === 200) {
              resolve();
            }
          }
        });
      })
    ).then(() => {
      this.setState({
        isBgLoading: false,
      });
      toast.success('อัพเดจสำเร็จ');
      this.handleNextStep();
    });
  };

  handleNextStep = () => {
    this.props.router.navigate(`${ROUTE_PATH.REWARD_POINT}`);
  };

  handleDelete = () => {
    this.setState({
      renderModal: (
        <Displays.CofirmBox
          label="คุณต้องการลบใช่หรือไม่ ?"
          labelBtn1="Confirm"
          labelBtn2="Cancel"
          onAction1={this.deleteAction}
          onAction2={this.handleCloseModal}
        />
      ),
    });
  };

  render() {
    const { isLoading, initialValues, isBgLoading, renderModal } = this.state;
    return (
      <RewardAddEditContainerStyled>
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading />
          </div>
        ) : (
          <>
            <Displays.BGLoading visible={isBgLoading} />
            <Forms.RewardForm
              initialValues={initialValues && initialValues}
              onCancel={this.handleNextStep}
              onSubmit={this.handleSubmit}
              onDelete={this.handleDelete}
            />
          </>
        )}
        <Modals.BodyEmpty
          isShowModal={renderModal}
          onCloseModal={this.handleCloseModal}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </RewardAddEditContainerStyled>
    );
  }
}

export default withRouter(RewardAddEditContainer);
