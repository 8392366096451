import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { DropdownStyled } from './styled';
import ReactDropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { Icons } from 'components'

const Dropdown = ({
  theme_standard,
  theme_normal,
  label,
  options,
  value,
  onChange,
  onChaneCustom,
  placeholder,
  errors,
  disabled,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_normal: theme_normal,
  });
  return (
    <DropdownStyled>
      <div className={customClass}>
        {label && <div className="label">{label}</div>}
        <ReactDropdown
          controlClassName="input_dropdown"
          arrowClassName="arrow_dropdown"
          placeholderClassName="myPlaceholderClassName"
          disabled={disabled}
          options={options}
          onChange={(e) => {
            onChange(e.value);
            onChaneCustom && onChaneCustom(e.value);
          }}
          value={value}
          placeholder={placeholder}
          arrowClosed={<div className='arrow'><Icons.ArrowDropdown color='#dcdcdc' width='13px' /></div>}
          arrowOpen={<div className='arrow'><Icons.ArrowDropdown translate="21.81" rotate="90" color='#dcdcdc' width='13px' /></div>}
        />
        {errors && <div className="errors">{errors}</div>}
      </div>
    </DropdownStyled>
  );
};

Dropdown.propTypes = {};

export default Dropdown;
