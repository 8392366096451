import styled from 'styled-components';

export const RewardGiftContainerStyled = styled.div`
  width: 100%;
  max-width: 600px;
  max-height: 80vh;
  overflow: auto;
  padding: 1rem;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .loading_show {
    margin-top: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
