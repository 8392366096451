import Avatar from './Avatar';
import Loading from './Loading';
import PaginationCustom from './PaginationCustom';
import BGLoading from './BGLoading';
import CofirmBox from './CofirmBox';

const EXPORT = {
  Avatar,
  Loading,
  PaginationCustom,
  BGLoading,
  CofirmBox,
};

export default EXPORT;
