import React, { useEffect } from 'react';
import { BannerFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Inputs, Buttons } from 'components';

const BannerForm = ({ initialValues, onCancel, onDelete, onSubmit }) => {
  const schema = yup.object().shape({
    name: yup.string().required('Banner name is required'),
    type: yup.string().required('Banner Type is required'),
    display_type: yup.string().required('Display type is required'),
    action_type: yup.string().required('Action type is required'),
  });

  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const watchAllField = watch();

  useEffect(() => {
    if (initialValues) {
      reset({
        ...initialValues,
        start_date: new Date(initialValues.start_date),
        end_date: new Date(initialValues.end_date),
        image_file: initialValues.img_path
          ? [{ media_uri: initialValues.img_path }]
          : [],
      });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BannerFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="body_form_campaign">
          <div className="input_type_wrap">
            <div className="itw_left_box">Banner name :</div>
            <div className="itw_right_box">
              <div className="w50">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.TextField
                      {...field}
                      // placeholder={t('test')}
                      errors={errors.name?.message}
                    />
                  )}
                  name="name"
                  defaultValue=""
                />
              </div>
            </div>
          </div>
          <div className="input_type_wrap">
            <div className="itw_left_box">Type :</div>
            <div className="itw_right_box">
              <div className="w50">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.Dropdown
                      {...field}
                      options={[
                        {
                          value: '1',
                          label: 'แสดงแบบไม่มีเงื่อนไข',
                        },
                        {
                          value: '2',
                          label: 'แสดงแบบใช้แต้ม',
                        },
                      ]}
                      errors={errors.type?.message}
                    />
                  )}
                  name="type"
                />
              </div>
            </div>
          </div>
          {watchAllField && watchAllField.type === '2' && (
            <div className="input_type_wrap">
              <div className="itw_left_box">Point :</div>
              <div className="itw_right_box">
                <div className="group_obj">
                  <div>From:</div>
                  <div className="w40">
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Inputs.TextField
                          {...field}
                          type="number"
                          min={0}
                          errors={errors.start_point?.message}
                        />
                      )}
                      name="start_point"
                      defaultValue=""
                    />
                  </div>
                  <div>To:</div>
                  <div className="w40">
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Inputs.TextField
                          {...field}
                          type="number"
                          min={0}
                          errors={errors.end_point?.message}
                        />
                      )}
                      name="end_point"
                      defaultValue=""
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="input_type_wrap">
            <div className="itw_left_box">Display type :</div>
            <div className="itw_right_box">
              <div className="w50">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.Dropdown
                      {...field}
                      options={[
                        {
                          value: '1',
                          label: 'แสดงแบบเปิดครั้งแรก',
                        },
                        {
                          value: '2',
                          label: 'แสดงแบบเปิดแอพใหม่',
                        },
                      ]}
                      errors={errors.display_type?.message}
                    />
                  )}
                  name="display_type"
                />
              </div>
            </div>
          </div>
          <div className="input_type_wrap">
            <div className="itw_left_box">Action type :</div>
            <div className="itw_right_box">
              <div className="w50">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.Dropdown
                      {...field}
                      options={[
                        {
                          value: '1',
                          label: 'อ่านอย่างเดียว',
                        },
                        {
                          value: '2',
                          label: 'ลิงค์',
                        },
                      ]}
                      errors={errors.action_type?.message}
                    />
                  )}
                  name="action_type"
                />
              </div>
            </div>
          </div>
          {watchAllField && watchAllField.action_type === '2' && (
            <div className="input_type_wrap">
              <div className="itw_left_box">Url :</div>
              <div className="itw_right_box">
                <div className="w50"></div>

                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.TextField
                      {...field}
                      // placeholder={t('test')}
                      errors={errors.url?.message}
                    />
                  )}
                  name="url"
                  defaultValue=""
                />
              </div>
            </div>
          )}
          <div className="input_type_wrap">
            <div className="itw_left_box">Publish date :</div>
            <div className="itw_right_box">
              <div className="group_obj">
                <div>From:</div>
                <div className="w40">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.DateTime
                        {...field}
                        placeholder={'วันเริ่ม'}
                        errors={errors.start_date?.message}
                        showYearDropdown
                      />
                    )}
                    name="start_date"
                    defaultValue={new Date()}
                  />
                </div>
                <div>To:</div>
                <div className="w40">
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.DateTime
                        {...field}
                        placeholder={'วันสิ้นสุด'}
                        errors={errors.end_date?.message}
                        showYearDropdown
                      />
                    )}
                    name="end_date"
                    defaultValue={new Date()}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="input_type_wrap">
            <div className="itw_left_box">Enable :</div>
            <div className="itw_right_box">
              <div className="w50">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputSwitch
                      {...field}
                      errors={errors.is_active?.message}
                    />
                  )}
                  name="is_enabled"
                  defaultValue={false}
                />
              </div>
            </div>
          </div>
          <div className="input_type_wrap">
            <div className="itw_left_box">Picture :</div>
            <div className="itw_right_box">
              <div className="w50">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.UploadImage
                      {...field}
                      theme_upload_image
                      fieldName="image"
                      scaleLabel="*** รูปขนาดกว้าง 397px สูง 190px"
                      errors={errors.front_img?.message}
                    />
                  )}
                  name="image_file"
                  defaultValue={[]}
                />
              </div>
            </div>
          </div>
          <div className="btn_zone">
            <div className="w120">
              <Buttons.BgStandard
                theme_gray
                label="Cancel"
                onClick={onCancel}
              />
            </div>
            {initialValues && (
              <div className="w120">
                <Buttons.BgStandard
                  theme_red
                  label="Delete"
                  onClick={onDelete}
                />
              </div>
            )}
            <div className="w120">
              <Buttons.BgStandard type="submit" label="Save" />
            </div>
          </div>
        </div>
      </form>
    </BannerFormStyled>
  );
};

BannerForm.propTypes = {};

export default BannerForm;
