import ArrowDropdown from './ArrowDropdown';
import CloseX from './CloseX';
import AddRound from './AddRound';
import ArrowLeftRight from './ArrowLeftRight';
import Loading from './Loading';

const EXPORT = {
  ArrowDropdown,
  AddRound,
  CloseX,
  ArrowLeftRight,
  Loading,
};

export default EXPORT;
