import styled from 'styled-components';

export const RewardGiftFormStyled = styled.div`
  .input_wrap {
    margin-bottom: 20px;
    .w50 {
      width: 50%;
    }
  }
`;
