import React, { useEffect } from 'react';
import { RewardFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Inputs, Buttons } from 'components';

const RewardForm = ({ onSubmit, onCancel, onDelete, initialValues }) => {
  const schema = yup.object().shape({
    code: yup.string().required('Reward Code is required'),
    name: yup.string().required('Reward Name is required'),
    description: yup.string().required('Description Name is required'),
    type: yup.string().required('Reward Type is required'),
  });

  const {
    control,
    // watch,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // const watchCampaignType = watch('type');

  useEffect(() => {
    if (initialValues) {
      reset({
        ...initialValues,
        expired_at: new Date(initialValues.expired_at),
        img_path_file: initialValues.img_path
          ? [{ media_uri: initialValues.img_path }]
          : [],
        reward_img_path_file: initialValues.reward_img_path
          ? [{ media_uri: initialValues.reward_img_path }]
          : [],
      });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <RewardFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="body_form_campaign">
          <div className="input_type_wrap">
            <div className="itw_left_box">Reward type</div>
            <div className="itw_right_box">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.Dropdown
                    {...field}
                    options={[
                      {
                        value: '1',
                        label: 'E-Coupon',
                      },
                      {
                        value: '2',
                        label: 'Coupon',
                      },
                      {
                        value: '3',
                        label: 'Other',
                      },
                    ]}
                    errors={errors.type?.message}
                  />
                )}
                name="type"
                // defaultValue={{ label: '1', value: '1' }}
              />
            </div>
          </div>
          <div className="input_warp">
            <div className="left_box">Reward code:</div>
            <div className="right_box">
              <div className="w50">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.TextField
                      {...field}
                      errors={errors.code?.message}
                    />
                  )}
                  name="code"
                  defaultValue=""
                />
              </div>
            </div>
          </div>
          <div className="input_warp">
            <div className="left_box">Reward name:</div>
            <div className="right_box">
              <div className="w50">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.TextField
                      {...field}
                      errors={errors.name?.message}
                    />
                  )}
                  name="name"
                  defaultValue=""
                />
              </div>
            </div>
          </div>
          <div className="input_warp">
            <div className="left_box">Reward description:</div>
            <div className="right_box">
              <div className="w50">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.TextArea
                      {...field}
                      errors={errors.description?.message}
                    />
                  )}
                  name="description"
                  defaultValue=""
                />
              </div>
            </div>
          </div>
          <div className="input_warp">
            <div className="left_box">Expired at:</div>
            <div className="right_box">
              <div className="w50">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.DateTime
                      {...field}
                      placeholder={'วันที่สิ้นสุด'}
                      errors={errors.expired_at?.message}
                      showYearDropdown
                    />
                  )}
                  name="expired_at"
                  defaultValue={new Date()}
                />
              </div>
            </div>
          </div>
          <div className="input_warp">
            <div className="left_box">Total amount</div>
            <div className="right_box">
              <div className="w50">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.TextField
                      {...field}
                      type="number"
                      min={initialValues ? initialValues.redeem_amount : 0}
                      errors={errors.total_amount?.message}
                    />
                  )}
                  name="total_amount"
                  defaultValue=""
                />
              </div>
            </div>
          </div>
          <div className="input_warp">
            <div className="left_box">Redeem point</div>
            <div className="right_box">
              <div className="w50">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.TextField
                      {...field}
                      type="number"
                      min={0}
                      errors={errors.redeem_point?.message}
                    />
                  )}
                  name="redeem_point"
                  defaultValue=""
                />
              </div>
            </div>
          </div>
          <div className="input_warp">
            <div className="left_box">Cost</div>
            <div className="right_box">
              <div className="w50">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.TextField
                      {...field}
                      type="number"
                      min={0}
                      errors={errors.cost?.message}
                    />
                  )}
                  name="cost"
                  defaultValue=""
                />
              </div>
            </div>
          </div>
          <div className="input_warp">
            <div className="left_box">Image</div>
            <div className="right_box">
              <div className="w50">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.UploadImage
                      {...field}
                      theme_upload_image
                      fieldName="img_path"
                      scaleLabel="*** รูปขนาดกว้าง 197px สูง 148px"
                      errors={errors.img_path_file?.message}
                    />
                  )}
                  name="img_path_file"
                  defaultValue={[]}
                />
              </div>
            </div>
          </div>
          <div className="input_warp">
            <div className="left_box">Image inside</div>
            <div className="right_box">
              <div className="w50">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.UploadImage
                      {...field}
                      theme_upload_image
                      fieldName="reward_img_path"
                      scaleLabel="*** รูปขนาดกว้าง 354px สูง 184px"
                      errors={errors.reward_img_path_file?.message}
                    />
                  )}
                  name="reward_img_path_file"
                  defaultValue={[]}
                />
              </div>
            </div>
          </div>
          <div className="input_warp">
            <div className="left_box">Active</div>
            <div className="right_box">
              <div className="w50">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.InputSwitch
                      {...field}
                      errors={errors.is_active?.message}
                    />
                  )}
                  name="is_active"
                  // defaultValue={[]}
                />
              </div>
            </div>
          </div>
          <div className="btn_zone">
            <div className="w120">
              <Buttons.BgStandard
                theme_gray
                label="Cancel"
                onClick={onCancel}
              />
            </div>
            {initialValues && (
              <div className="w120">
                <Buttons.BgStandard
                  theme_red
                  label="Delete"
                  onClick={onDelete}
                  disabled={!initialValues.is_delete}
                />
              </div>
            )}
            <div className="w120">
              <Buttons.BgStandard
                type="submit"
                label="Save"
                // disabled={initialValues && !initialValues.is_edit}
              />
            </div>
          </div>
        </div>
      </form>
    </RewardFormStyled>
  );
};

RewardForm.propTypes = {};

export default RewardForm;
