import React from 'react';
import { CampaignExportContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';
import { portalService } from 'apiServices';
import * as XLSX from 'xlsx';
import moment from 'moment';
import 'moment-timezone';
import { IMG_PATH } from 'utils/constants/imgPath';
import { Displays, Inputs, Tables } from 'components';

class CampaignExportContainer extends React.Component {
  state = {
    isLoading: true,
    pagination: null,
    filterQuery: null,
    perPage: 10,
    dataListCustomer: [],
  };

  componentDidMount() {
    const { perPage } = this.state;

    scrollToTopPageFN();
    this.fetchData(`?limit=${perPage}`);
  }

  fetchData = async (stringQuery) => {
    let res = await Promise.all([
      this.fetchDataCapaignHistory(stringQuery),
      this.fetchCampaignList(),
    ]);

    if (res[0] && res[1]) {
      this.setState({
        data: res[0].docs,
        pagination: {
          last_page: res[0].totalPages,
          current_page: res[0].page,
        },
        dataListCustomer: res[1],
        isLoading: false,
      });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  fetchCampaignList = async () => {
    let res = await portalService.GET_CAMPAIGN_ALL_LIST();
    if (res && res.status === 200) {
      let tmpData = [];
      if (res.data && res.data.length > 0) {
        res.data.map((e) =>
          tmpData.push({
            label: e.name,
            value: e.name,
          })
        );
      }
      return tmpData;
    }
  };

  fetchDataCapaignHistory = async (stringQuery) => {
    let res = await portalService.GET_CAMPAIGN_HISTORY_LIST(stringQuery);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  handleFilterKeyword = (e) => {
    const { perPage } = this.state;
    this.setState({
      filterQuery: e,
    });
    this.handleJoinQuery(e, perPage, 1);
  };

  handlePageClick = (e) => {
    const { filterQuery, perPage } = this.state;
    this.handleJoinQuery(filterQuery, perPage, e.selected + 1);
  };

  handleJoinQuery = (query, per_page, page) => {
    let joinQuery = query
      ? `${query}&limit=${per_page}&page=${page}`
      : `?limit=${per_page}&page=${page}`;
    this.setState({
      isLoading: true,
    });
    this.fetchSearch(joinQuery);
  };

  fetchSearch = async (joinQuery) => {
    let res = await this.fetchDataCapaignHistory(joinQuery);
    if (res) {
      this.setState({
        data: res.docs,
        pagination: {
          last_page: res.totalPages,
          current_page: res.page,
        },
        isLoading: false,
      });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleExport = async () => {
    const { filterQuery } = this.state;
    this.setState({
      isBgLoading: true,
    });
    let joinQuery = filterQuery
      ? `${filterQuery}&pagination=false`
      : `?pagination=false`;
    let res = await portalService.GET_CAMPAIGN_HISTORY_LIST(joinQuery);
    if (res && res.status === 200) {
      var wb = XLSX.utils.book_new();
      var ws_name = 'SheetJS';
      /* make worksheet */
      var ws_data = res.data.docs.reduce((result, e, i) => {
        result.push([
          i + 1,
          e.campaign_code,
          e.campaign_name,
          e.customer_user_id,
          moment(e.created_at).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm:ss'),
          e.customer_cu_id,
          e.campaign_serial_no[0] ? e.campaign_serial_no[0].code : '',
          e.campaign_serial_no[1] ? e.campaign_serial_no[1].code : '',
          e.campaign_serial_no[2] ? e.campaign_serial_no[2].code : '',
          e.campaign_serial_no[3] ? e.campaign_serial_no[3].code : '',
          e.campaign_serial_no[4] ? e.campaign_serial_no[4].code : '',
          e.campaign_serial_no[5] ? e.campaign_serial_no[5].code : '',
          e.campaign_serial_no[6] ? e.campaign_serial_no[6].code : '',
          e.campaign_serial_no[7] ? e.campaign_serial_no[7].code : '',
          e.campaign_serial_no[8] ? e.campaign_serial_no[8].code : '',
          e.campaign_serial_no[9] ? e.campaign_serial_no[9].code : '',
          e.campaign_document[0]
            ? IMG_PATH + e.campaign_document[0].img_path
            : '',
          e.campaign_document[1]
            ? IMG_PATH + e.campaign_document[1].img_path
            : '',
          e.campaign_document[2]
            ? IMG_PATH + e.campaign_document[2].img_path
            : '',
          e.campaign_document[3]
            ? IMG_PATH + e.campaign_document[3].img_path
            : '',
          e.campaign_document[4]
            ? IMG_PATH + e.campaign_document[4].img_path
            : '',
          e.campaign_document[5]
            ? IMG_PATH + e.campaign_document[5].img_path
            : '',
          e.campaign_document[6]
            ? IMG_PATH + e.campaign_document[6].img_path
            : '',
          e.campaign_document[7]
            ? IMG_PATH + e.campaign_document[7].img_path
            : '',
          e.campaign_document[8]
            ? IMG_PATH + e.campaign_document[8].img_path
            : '',
          e.campaign_document[9]
            ? IMG_PATH + e.campaign_document[9].img_path
            : '',
        ]);
        return result;
      }, []);
      ws_data.splice(0, 0, [
        'No',
        'Campaign code',
        'Campaign name',
        'User ID',
        'Created at',
        'Cu code',
        'So no.1',
        'So no.2',
        'So no.3',
        'So no.4',
        'So no.5',
        'So no.6',
        'So no.7',
        'So no.8',
        'So no.9',
        'So no.10',
        'Picture no.1',
        'Picture no.2',
        'Picture no.3',
        'Picture no.4',
        'Picture no.5',
        'Picture no.6',
        'Picture no.7',
        'Picture no.8',
        'Picture no.9',
        'Picture no.10',
      ]);
      var ws = XLSX.utils.aoa_to_sheet(ws_data);
      var wscols = [
        { wch: 5 },
        { wch: 15 },
        { wch: 50 },
        { wch: 35 },
        { wch: 25 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 35 },
        { wch: 35 },
        { wch: 35 },
        { wch: 35 },
        { wch: 35 },
        { wch: 35 },
        { wch: 35 },
        { wch: 35 },
        { wch: 35 },
        { wch: 35 },
      ];
      ws['!cols'] = wscols;
      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(
        wb,
        `Campaign report ${moment()
          .tz('Asia/Bangkok')
          .format('DD/MM/YYYY')}.xlsx`
      );
      this.setState({
        isBgLoading: false,
      });
    }
  };

  render() {
    const { isLoading, isBgLoading, pagination, data, dataListCustomer } =
      this.state;
    return (
      <CampaignExportContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        <div className="filter_layout">
          <Inputs.InputFilter
            theme_standard_input_filter
            dataFilter={dataFilter({ data: dataListCustomer })}
            btnLabel="ค้นหา"
            onQueryFilter={this.handleFilterKeyword}
            onExport={this.handleExport}
          />
        </div>
        {isLoading ? (
          <Displays.Loading theme_standard_loading heightLayout="386px" />
        ) : (
          <Tables.Standard
            columns={columns}
            data={data}
            showPagination
            pagination={pagination}
            handlePageClick={this.handlePageClick}
          />
        )}
      </CampaignExportContainerStyled>
    );
  }
}

const dataFilter = ({ data }) => [
  {
    accessor: 'campaign_names',
    length: '311px',
    filter: {
      fieldQuery: 'campaign_names',
      type: 'multiSelect',
      options: data,
      placeholder: 'ค้นหา Campaign',
      label: 'Select Campaign : ',
    },
  },
  {
    accessor: 'date',
    filter: {
      fieldQuery: 'date',
      type: 'dateRank',
      label: 'Date Range : ',
    },
  },
];

const columns = [
  {
    Header: <div style={{ textAlign: 'left' }}>Campaign code</div>,
    accessor: 'campaign_code',
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Campaign name</div>,
    accessor: 'campaign_name',
  },
  {
    Header: <div style={{ textAlign: 'left' }}>User ID</div>,
    accessor: 'customer_user_id',
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Created at</div>,
    accessor: 'created_at',
    Cell: (props) =>
      moment(props.value).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm:ss'),
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Cu code</div>,
    accessor: 'customer_cu_id',
    Cell: (props) => <div style={{ textAlign: 'left' }}>{props.value}</div>,
  },
  {
    Header: <div style={{ textAlign: 'left' }}>So no. 1</div>,
    accessor: 'so_no_1',
    Cell: (props) =>
      props.row.original.campaign_serial_no[0]
        ? props.row.original.campaign_serial_no[0].code
        : '',
  },
  {
    Header: <div style={{ textAlign: 'left' }}>So no. 2</div>,
    accessor: 'so_no_2',
    Cell: (props) =>
      props.row.original.campaign_serial_no[1]
        ? props.row.original.campaign_serial_no[1].code
        : '',
  },
  {
    Header: <div style={{ textAlign: 'left' }}>So no. 3</div>,
    accessor: 'so_no_3',
    Cell: (props) =>
      props.row.original.campaign_serial_no[2]
        ? props.row.original.campaign_serial_no[2].code
        : '',
  },
  {
    Header: <div style={{ textAlign: 'left' }}>So no. 4</div>,
    accessor: 'so_no_4',
    Cell: (props) =>
      props.row.original.campaign_serial_no[3]
        ? props.row.original.campaign_serial_no[3].code
        : '',
  },
  {
    Header: <div style={{ textAlign: 'left' }}>So no. 5</div>,
    accessor: 'so_no_5',
    Cell: (props) =>
      props.row.original.campaign_serial_no[4]
        ? props.row.original.campaign_serial_no[4].code
        : '',
  },
  {
    Header: <div style={{ textAlign: 'left' }}>So no. 6</div>,
    accessor: 'so_no_6',
    Cell: (props) =>
      props.row.original.campaign_serial_no[5]
        ? props.row.original.campaign_serial_no[5].code
        : '',
  },
  {
    Header: <div style={{ textAlign: 'left' }}>So no. 7</div>,
    accessor: 'so_no_7',
    Cell: (props) =>
      props.row.original.campaign_serial_no[6]
        ? props.row.original.campaign_serial_no[6].code
        : '',
  },
  {
    Header: <div style={{ textAlign: 'left' }}>So no. 8</div>,
    accessor: 'so_no_8',
    Cell: (props) =>
      props.row.original.campaign_serial_no[7]
        ? props.row.original.campaign_serial_no[7].code
        : '',
  },
  {
    Header: <div style={{ textAlign: 'left' }}>So no. 9</div>,
    accessor: 'so_no_9',
    Cell: (props) =>
      props.row.original.campaign_serial_no[8]
        ? props.row.original.campaign_serial_no[8].code
        : '',
  },
  {
    Header: <div style={{ textAlign: 'left' }}>So no. 10</div>,
    accessor: 'so_no_10',
    Cell: (props) =>
      props.row.original.campaign_serial_no[9]
        ? props.row.original.campaign_serial_no[9].code
        : '',
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Picture no. 1</div>,
    accessor: 'pic_no_1',
    Cell: (props) =>
      props.row.original.campaign_document[0]
        ? IMG_PATH + props.row.original.campaign_document[0].img_path
        : '',
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Picture no. 2</div>,
    accessor: 'pic_no_2',
    Cell: (props) =>
      props.row.original.campaign_document[1]
        ? IMG_PATH + props.row.original.campaign_document[1].img_path
        : '',
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Picture no. 3</div>,
    accessor: 'pic_no_3',
    Cell: (props) =>
      props.row.original.campaign_document[2]
        ? IMG_PATH + props.row.original.campaign_document[2].img_path
        : '',
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Picture no. 4</div>,
    accessor: 'pic_no_4',
    Cell: (props) =>
      props.row.original.campaign_document[3]
        ? IMG_PATH + props.row.original.campaign_document[3].img_path
        : '',
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Picture no. 5</div>,
    accessor: 'pic_no_5',
    Cell: (props) =>
      props.row.original.campaign_document[4]
        ? IMG_PATH + props.row.original.campaign_document[4].img_path
        : '',
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Picture no. 6</div>,
    accessor: 'pic_no_6',
    Cell: (props) =>
      props.row.original.campaign_document[5]
        ? IMG_PATH + props.row.original.campaign_document[5].img_path
        : '',
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Picture no. 7</div>,
    accessor: 'pic_no_7',
    Cell: (props) =>
      props.row.original.campaign_document[6]
        ? IMG_PATH + props.row.original.campaign_document[6].img_path
        : '',
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Picture no. 8</div>,
    accessor: 'pic_no_8',
    Cell: (props) =>
      props.row.original.campaign_document[7]
        ? IMG_PATH + props.row.original.campaign_document[7].img_path
        : '',
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Picture no. 9</div>,
    accessor: 'pic_no_9',
    Cell: (props) =>
      props.row.original.campaign_document[8]
        ? IMG_PATH + props.row.original.campaign_document[8].img_path
        : '',
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Picture no. 10</div>,
    accessor: 'pic_no_10',
    Cell: (props) =>
      props.row.original.campaign_document[9]
        ? IMG_PATH + props.row.original.campaign_document[9].img_path
        : '',
  },
];

export default withRouter(CampaignExportContainer);
