import React from 'react';
import { RewardContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import moment from 'moment';
import { portalService } from 'apiServices';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';
import { toast } from 'react-toastify';
import { Buttons, Inputs, Tables, Displays } from 'components';

class RewardContainer extends React.Component {
  state = {};

  componentDidMount() {
    scrollToTopPageFN();
  }

  handleChangeFile = (e) => {
    this.setState({
      fileCurrent: e,
    });
  };

  handleUpload = async (isECoupon) => {
    const { fileCurrent } = this.state;
    this.setState({
      isBgLoading: true,
    });
    if (!fileCurrent) {
      toast.error(`กรุณาอัพโหลดไฟล์`);
    } else {
      const bodyFormData = new FormData();
      bodyFormData.append('file', fileCurrent[0]);
      if (isECoupon) {
        bodyFormData.set('is_ecoupon', true);
      }
      let res = await portalService.POST_IMPORT_REWARD(bodyFormData);
      if (res && res.status === 200) {
        this.setState({
          isBgLoading: false,
          fileCurrent: false,
          data: res.data,
        });
        toast.success('อัพโหลดสำเร็จ');
      } else {
        this.setState({
          isBgLoading: false,
          fileCurrent: false,
        });
        toast.error(res.Message);
      }
    }
  };

  render() {
    const { isLoading, isBgLoading, data, fileCurrent } = this.state;
    return (
      <RewardContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        <div className="input_upload_wrap">
          <div className="upload_block">
            <Inputs.UploadImage
              theme_upload_image
              value={fileCurrent}
              acceptType="csv"
              onChange={this.handleChangeFile}
            />
          </div>
          <div className="btn_action_filter">
            <Buttons.BgStandard
              label={'Upload'}
              onClick={() => this.handleUpload(false)}
            />
          </div>
          <div
            className="btn_upload_ecoupon"
            onClick={() => this.handleUpload(true)}
          >
            ecoupon
          </div>
        </div>
        {isLoading ? (
          <Displays.Loading theme_standard_loading heightLayout="311px" />
        ) : (
          data && <Tables.Standard columns={columns} data={data} />
        )}
      </RewardContainerStyled>
    );
  }
}

const columns = [
  {
    Header: 'Upload date',
    accessor: 'created_at',
    Cell: (props) => (
      <div>{moment(props.value).format('DD/MM/YYYY HH:mm')}</div>
    ),
  },
  {
    Header: 'Redeem type',
    accessor: 'type',
    Cell: (props) => <div>{props.value}</div>,
  },
  {
    Header: 'E-coupon/Coupon/Other code',
    accessor: 'code',
  },
  {
    Header: 'E-coupon/Coupon/Other name',
    accessor: 'name',
  },
  {
    Header: 'Expire date',
    accessor: 'expire_date',
  },
  {
    Header: 'QTY',
    accessor: 'total_amount',
  },
  {
    Header: 'E-coupon cost',
    accessor: 'cost',
  },
  {
    Header: 'Redeem point rule/pc',
    accessor: 'redeem_point',
  },
];

export default withRouter(RewardContainer);
