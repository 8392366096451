import React, { useEffect } from 'react';
import { RegistrationFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Buttons, Inputs } from 'components';

const RegistrationForm = ({ initialValues, onCancel, onSubmit }) => {
  const schema = yup.object().shape({
    // test: yup.string().required('New password is required'),
  });

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (initialValues) {
      reset({
        see_user_manual_img: initialValues.see_user_manual_img
          ? [{ media_uri: initialValues.see_user_manual_img }]
          : [],
        see_cu_manual_img: initialValues.see_cu_manual_img
          ? [{ media_uri: initialValues.see_cu_manual_img }]
          : [],
      });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <RegistrationFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.UploadImage
                {...field}
                theme_upload_image
                label="How to see Login ID picture : "
                errors={errors.see_user_manual_img?.message}
              />
            )}
            name="see_user_manual_img"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.UploadImage
                {...field}
                theme_upload_image
                label="How to see Customer code picture : "
                errors={errors.see_cu_manual_img?.message}
              />
            )}
            name="see_cu_manual_img"
            defaultValue=""
          />
        </div>
        <div className="btn_zone">
          <div className="w120">
            <Buttons.BgStandard theme_gray label="Back" onClick={onCancel} />
          </div>
          <div className="w120">
            <Buttons.BgStandard type="submit" label="Save" />
          </div>
        </div>
      </form>
    </RegistrationFormStyled>
  );
};

RegistrationForm.propTypes = {};

export default RegistrationForm;
