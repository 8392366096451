import React from 'react';
import { RewardPointContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';
import { portalService } from 'apiServices';
import moment from 'moment';
import 'moment-timezone';
import { MdAdd, MdSort, MdCardGiftcard } from 'react-icons/md';
import { RenderCommaMoney } from 'utils/functions/FormatMoney';
import { RewardSort, RewardGiftContainer } from '../Modals';
import { Buttons, Inputs, Displays, Tables, Modals } from 'components';

class RewardPointContainer extends React.Component {
  state = {
    isLoading: true,
    pagination: null,
    perPage: 10,
  };

  componentDidMount() {
    const { perPage } = this.state;
    scrollToTopPageFN();
    this.fetchData(`?limit=${perPage}&sort=sequence`);
  }

  fetchData = async (stringQuery) => {
    let res = await portalService.GET_REWARD_LIST(stringQuery);
    if (res && res.status === 200) {
      this.setState({
        data: res.data.docs,
        isLoading: false,
        pagination: {
          last_page: res.data.totalPages,
          current_page: res.data.page,
        },
      });
    }
  };

  handleFilterKeyword = (e) => {
    const { perPage } = this.state;
    this.setState({
      filterQuery: e,
    });
    this.handleJoinQuery(e, perPage, 1);
  };

  handleClickCreate = (e) => {
    this.props.router.navigate(`${ROUTE_PATH.REWARD_POINT_DETAIL}/${e}`);
  };

  handlePageClick = (e) => {
    const { filterQuery, perPage } = this.state;
    this.handleJoinQuery(filterQuery, perPage, e.selected + 1);
  };

  handleJoinQuery = (query, per_page, page) => {
    let joinQuery = query
      ? `${query}&limit=${per_page}&page=${page}&sort=sequence`
      : `?limit=${per_page}&page=${page}&sort=sequence`;
    this.setState({
      isLoading: true,
    });
    this.fetchData(joinQuery);
  };

  handleCloseModal = () => {
    this.setState({
      renderModal: false,
    });
  };

  handleClickSort = () => {
    const { filterQuery } = this.state;
    this.setState({
      renderModal: <RewardSort onUpdated={() => this.fetchData(filterQuery)} />,
    });
  };

  handleClickGift = (id) => {
    this.setState({
      renderModal: (
        <RewardGiftContainer id={id} onGiftSuccess={this.handleGiftSuccess} />
      ),
    });
  };

  handleGiftSuccess = () => {
    const { filterQuery, perPage, pagination } = this.state;
    this.handleCloseModal();
    this.fetchData(
      `${filterQuery}&limit=${perPage}&page=${pagination.current_page}&sort=sequence`
    );
  };

  render() {
    const { isLoading, pagination, renderModal, data } = this.state;
    return (
      <RewardPointContainerStyled>
        <div className="btn_create">
          <div className="w120">
            <Buttons.BgIconPrefix
              label="New"
              icon={<MdAdd size={18} />}
              onClick={() => this.handleClickCreate('create')}
            />
          </div>
          <div className="w120">
            <Buttons.BgIconPrefix
              label="Sort"
              icon={<MdSort size={18} />}
              onClick={() => this.handleClickSort()}
            />
          </div>
        </div>
        <Inputs.InputFilter
          theme_standard_input_filter
          dataFilter={dataFilter}
          btnLabel="ค้นหา"
          onQueryFilter={this.handleFilterKeyword}
        />
        {isLoading ? (
          <Displays.Loading theme_standard_loading heightLayout="324px" />
        ) : (
          <>
            <div className="body_table  ">
              <Tables.Standard
                columns={columns({
                  handleClickDetail: this.handleClickCreate,
                  handleClickGift: this.handleClickGift,
                })}
                data={data}
                showPagination
                pagination={pagination}
                handlePageClick={this.handlePageClick}
              />
            </div>
          </>
        )}
        <Modals.BodyEmpty
          isShowModal={renderModal}
          onCloseModal={this.handleCloseModal}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </RewardPointContainerStyled>
    );
  }
}

const dataFilter = [
  {
    accessor: 'type',
    length: '311px',
    filter: {
      fieldQuery: 'type',
      type: 'dropdown',
      options: [
        {
          label: 'All',
          value: '0',
        },
        {
          label: 'E-coupon',
          value: '1',
        },
        {
          label: 'Coupon',
          value: '2',
        },
        {
          label: 'Other',
          value: '3',
        },
      ],
      placeholder: 'Redeem type',
      label: 'Redeem type : ',
    },
  },
  {
    accessor: 'name',
    length: '311px',
    filter: {
      fieldQuery: 'name',
      type: 'text',
      label: 'Reward search',
      placeholder: 'Reward name',
    },
  },
];

const columns = ({ handleClickDetail, handleClickGift }) => [
  {
    Header: <div style={{ textAlign: 'left' }}>Crate Date</div>,
    accessor: 'created_at',
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {moment(props.value).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm')}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Reward Name</div>,
    accessor: 'name',
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {props.value}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Redeem Point</div>,
    accessor: 'redeem_point',
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {RenderCommaMoney(Number(props.value), true)}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Cost</div>,
    accessor: 'cost',
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {RenderCommaMoney(Number(props.value), true)}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Total Amount</div>,
    accessor: 'total_amount',
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {RenderCommaMoney(Number(props.value), true)}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Total Redeem</div>,
    accessor: 'redeem_amount',
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {RenderCommaMoney(Number(props.value), true)}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Total Remain</div>,
    accessor: 'total_reamin',
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {RenderCommaMoney(
          Number(
            props.row.original.total_amount - props.row.original.redeem_amount
          ),
          true
        )}
      </div>
    ),
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (props) =>
      props.row.original.type === '1' &&
      props.row.original.redeem_amount < props.row.original.total_amount ? (
        <div
          style={{ textAlign: 'right' }}
          onClick={() => handleClickGift(props.row.original._id)}
        >
          <MdCardGiftcard size={24} />
        </div>
      ) : (
        <></>
      ),
  },
];

export default withRouter(RewardPointContainer);
