import styled from 'styled-components';

export const PaginationCustomStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .theme_standard_pagination {
    .pagination {
      display: flex;
      width: 100%;
      list-style: none;
      justify-content: flex-end;
      align-items: center;
      margin: 0;
      padding: 0;
      .previous {
        display: flex;
      }
      .next {
        display: flex;
      }
      li {
        cursor: pointer;
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        color: ${({ theme }) => theme.COLORS.BLACK_2};
        a {
          padding: 0 10px;
        }
      }
      .active {
        background: ${({ theme }) => theme.COLORS.BLUE_4};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
        border-radius: 50%;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .pagination li.disabled a {
      display: none;
    }
  }
`;
