import styled from 'styled-components';

export const DropdownStyled = styled.div`
  .label {
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.COLORS.BLUE_2};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
  }
  .input_dropdown {
    border: none;
    width: 100%;
    box-sizing: border-box;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    border: 0.0625rem solid ${({ theme }) => theme.COLORS.GRAY_1};
    padding: 0.3125rem 1.25rem;
    color: ${({ theme }) => theme.COLORS.GRAY_2};
    height: 2.75rem;
    border-radius: 1rem;
    display: flex;
    align-items: center;
  }
  .arrow_dropdown {
    top: 21px;
  }
  .Dropdown-control.input_dropdown {
    display: flex;
    justify-content: space-between;
    .Dropdown-placeholder.is-selected {
      color: ${({ theme }) => theme.COLORS.BLUE_2};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
    }
    .arrow {  
      display: flex;
    }
  }
  .myPlaceholderClassName {
    color: ${({ theme }) => theme.COLORS.BLUE_2};
      font-size: ${({ theme }) => theme.FONT.SIZE.S13};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
  }
  .errors {
    margin-top: 2px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }
  .Dropdown-menu {
    color: ${({ theme }) => theme.COLORS.BLUE_2};
    font-size: ${({ theme }) => theme.FONT.SIZE.S13};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
    background-color: ${({ theme }) => theme.COLORS.WHITE_1};
    border-radius: 5px;
    box-sizing: border-box;
    margin-top: 4px;
  }

  .theme_standard {
  }
`;
