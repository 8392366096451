import styled from 'styled-components';

export const CampaignType2FormStyled = styled.div`
  .input_warp {
    margin-bottom: 20px;
    display: flex;
    width: 100%;
    &.mt30 {
      margin-top: 30px;
    }
    .left_box {
      width: 20%;
      display: flex;
      align-items: center;
    }
    .right_box {
      width: 80%;
      display: flex;
      align-items: flex-start;
      column-gap: 1rem;
      &.display_col {
        flex-direction: column;
        row-gap: 20px;
        border-top: 2px solid ${({ theme }) => theme.COLORS.GRAY_1};
        padding-top: 20px;
      }
      .w50 {
        width: 50%;
        &.flex {
          display: flex;
          column-gap: 10px;
          align-items: center;
        }
        .bText {
          cursor: pointer;
          font-size: ${({ theme }) => theme.FONT.SIZE.S30};
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
          color: green;
          &.red {
            color: ${({ theme }) => theme.COLORS.RED_1};
          }
        }
      }
      .group_obj {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .w40 {
          width: 40%;
        }
      }
      .color_zone {
        display: flex;
        column-gap: 10px;
        .box_color {
          .label {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
`;
