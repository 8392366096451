import BannerForm from './BannerForm';
import CampaignForm from './CampaignForm';
import DeliveryForm from './DeliveryForm';
import LoginForm from './LoginForm';
import RegistrationForm from './RegistrationForm';
import InformationForm from './InformationForm';
import RewardForm from './RewardForm';
import RewardGiftForm from './RewardGiftForm';
import UserInterfaceForm from './UserInterfaceForm';

const EXPORT = {
  BannerForm,
  CampaignForm,
  DeliveryForm,
  LoginForm,
  RegistrationForm,
  RewardForm,
  InformationForm,
  RewardGiftForm,
  UserInterfaceForm,
};

export default EXPORT;
