import styled from 'styled-components';

export const SystemLayoutStyled = styled.div`
  min-height: 100vh;
  display: flex;
  .right_mainlayout {
    flex: 1;
    background: ${({ theme }) => theme.COLORS.GRAY_4};
    .body_mainlayout {
      padding: 1.25rem 2.5rem;
    }
  }
`;
