import React, { useEffect } from 'react';
import { AaPlatformStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Buttons, Inputs } from 'components';

const AaPlatform = ({ onSubmit, initialValues }) => {
  const schema = yup.object().shape({
    // test: yup.string().required('New password is required'),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (initialValues) {
      reset({
        ...initialValues,
      });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AaPlatformStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_row">
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  label="Firstname : "
                  errors={errors.firstname?.message}
                />
              )}
              name="firstname"
              defaultValue=""
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  label="Lastname : "
                  errors={errors.lastname?.message}
                />
              )}
              name="lastname"
              defaultValue=""
            />
          </div>
        </div>
        <div className="input_row">
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  label="Address : "
                  errors={errors.address?.message}
                />
              )}
              name="address"
              defaultValue=""
            />
          </div>
        </div>
        <div className="input_row">
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  label="CU code : "
                  errors={errors.cu_id?.message}
                />
              )}
              name="cu_id"
              defaultValue=""
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  label="Email : "
                  errors={errors.email?.message}
                />
              )}
              name="email"
              defaultValue=""
            />
          </div>
        </div>
        <div className="input_row">
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  label="Login ID : "
                  errors={errors.user_id?.message}
                />
              )}
              name="user_id"
              defaultValue=""
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  label="Tel : "
                  errors={errors.phone_number?.message}
                />
              )}
              name="phone_number"
              defaultValue=""
            />
          </div>
        </div>
        <div className="btn_zone">
          {/* <div className="w120">
            <Buttons.BgStandard theme_gray label="Back" onClick={onCancel} />
          </div> */}
          <div className="w120">
            <Buttons.BgStandard
              type="submit"
              label="Save"
              disabled={!initialValues}
            />
          </div>
        </div>
      </form>
    </AaPlatformStyled>
  );
};

AaPlatform.propTypes = {};

export default AaPlatform;
