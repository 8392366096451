import React from 'react';
import { ComponentContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { Inputs, Buttons } from 'components'

class ComponentContainer extends React.Component {
  render() {
    return (
      <ComponentContainerStyled>
        <div className='body_container'>
          <div className='box1'>
            <Inputs.TextField
              label='Email'
              placeholder='Email'
            />
          </div>
          <div className='box1'>
            <Inputs.TextField
              label='Passwords'
              placeholder='Passwords'
              type='password'
            />
          </div>
          <div className='box1'>
            <Inputs.Dropdown
              options={[
                { label: 'กรุงเทพ', value: '1' },
                { label: 'พัทยา', value: '2' },
              ]}
              placeholder='Dropdown'
              label='Dropdown '
            />
          </div>
          <div className='box1'>
            <Buttons.BgStandard
              label='Confirm identity'
            />
          </div>
          <div className='box1'>
            <Inputs.Radio
              label='Radio'
              options={[
                { label: 'เลขบัตรประจำตัวประชาชน', value: '1' },
                { label: 'เลขพาสปอร์ต', value: '2' },
              ]}
            />
          </div>
          <div className='box1'>
            <Inputs.InputCheckBox
              theme_standard_input_check_box
              label="อื่นๆ"
            />
          </div>
          <div className='box1'>
            <Inputs.UploadImage
              theme_upload_image
              acceptType="cover"
              maxMBFileSizeWarning="1"
              // svg={<Icons.Delete3 />}
              theme_left_right={false}
            />
          </div>
        </div>
      </ComponentContainerStyled>
    );
  }
}

export default withRouter(ComponentContainer);
