import React from 'react';
import { BannerAddEditContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';
import { portalService } from 'apiServices';
import { toast } from 'react-toastify';
import { Forms, Displays, Modals } from 'components';

class BannerAddEditContainer extends React.Component {
  state = {
    isLoading: true,
    labelSuccess:
      this.props.router.params.id === 'create' ? 'สร้างสำเร็จ' : 'แก้ไขสำเร็จ',
    labelError:
      this.props.router.params.id === 'create'
        ? 'สร้างไม่สำเร็จ'
        : 'แก้ไขไม่สำเร็จ',
  };

  componentDidMount() {
    scrollToTopPageFN();
    if (this.props.router.params.id !== 'create') {
      this.fetchData();
    } else {
      setTimeout(() => {
        this.setState({
          isLoading: false,
        });
      }, 400);
    }
  }

  fetchData = async () => {
    let res = await portalService.GET_BANNER_DETAIL(
      this.props.router.params.id
    );
    if (res && res.status === 200) {
      this.setState({
        initialValues: res.data,
        isLoading: false,
      });
    }
  };

  handleCloseModal = () => {
    this.setState({
      renderModal: null,
    });
  };

  handleDelete = () => {
    this.setState({
      renderModal: (
        <Displays.CofirmBox
          label="คุณต้องการลบใช่หรือไม่ ?"
          labelBtn1="Confirm"
          labelBtn2="Cancel"
          onAction1={this.deleteAction}
          onAction2={this.handleCloseModal}
        />
      ),
    });
  };

  deleteAction = async () => {
    this.setState({
      isBgLoading: true,
    });
    let res = await portalService.DELETE_BANNER(this.props.router.params.id);
    if (res && res.status === 200) {
      toast.success('ลบสำเร็จ');
      this.handleNextStep();
    } else {
      toast.error('ลบไม่สำรเร็จ');
    }
  };

  handleSubmit = async (values) => {
    const { labelSuccess, labelError } = this.state;
    this.setState({
      isBgLoading: true,
    });
    let params = {
      ...values,
    };
    let res =
      this.props.router.params.id === 'create'
        ? await portalService.POST_BANNER_DETAIL(params)
        : await portalService.PUT_BANNER_UPDATE(
            this.props.router.params.id,
            params
          );
    if (res && res.status === 200) {
      let filesTemp = [];
      if (values.image_file) {
        filesTemp = values.image_file.filter((e) => e.isNewUpload);
      }
      if (filesTemp.length === 0) {
        toast.success(labelSuccess);
        this.handleNextStep();
      } else {
        this.handleUploadContentMedia(res.data._id, filesTemp);
      }
    } else {
      this.setState({
        isBgLoading: false,
      });
      toast.error(labelError);
    }
  };

  handleUploadContentMedia = (campaignID, files) => {
    Promise.all(
      files.map((e) => {
        return new Promise(async (resolve) => {
          if (e.isNewUpload) {
            const bodyFormData = new FormData();
            bodyFormData.set('popup_banner_id', campaignID);
            bodyFormData.append('image', e);
            let res = await portalService.POST_BANNER_UPLOAD(bodyFormData);
            if (res && res.status === 200) {
              resolve();
            }
          }
        });
      })
    ).then(() => {
      this.setState({
        isBgLoading: false,
      });
      toast.success('อัพเดจสำเร็จ');
      this.handleNextStep();
    });
  };

  handleNextStep = () => {
    this.props.router.navigate(`${ROUTE_PATH.BANNER_DATA}`);
  };

  render() {
    const { isLoading, initialValues, isBgLoading, renderModal } = this.state;
    return (
      <BannerAddEditContainerStyled>
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading />
          </div>
        ) : (
          <>
            <Displays.BGLoading visible={isBgLoading} />
            <Forms.BannerForm
              initialValues={initialValues && initialValues}
              onCancel={this.handleNextStep}
              onSubmit={this.handleSubmit}
              onDelete={this.handleDelete}
            />
          </>
        )}
        <Modals.BodyEmpty
          isShowModal={renderModal}
          onCloseModal={this.handleCloseModal}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </BannerAddEditContainerStyled>
    );
  }
}

export default withRouter(BannerAddEditContainer);

// const mapStateToProps = (state) => ({
//   authenRedux: state.authenRedux,
//   companySelectedRedux: state.companySelectedRedux,
// });

// const mapDispatchToProps = (dispatch) => ({});

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withRouter(BannerAddEditContainer)));
