import React from "react";
import { UserPointContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import { portalService } from "apiServices";
import * as XLSX from "xlsx";
import moment from "moment";
import "moment-timezone";
import { RenderCommaMoney } from "utils/functions/FormatMoney";
import { Displays, Inputs, Tables } from "components";

class UserPointContainer extends React.Component {
  state = { isLoading: true, pagination: null, filterQuery: null, perPage: 10 };

  componentDidMount() {
    scrollToTopPageFN();
    this.fetchData(`?pagination=false`);
  }

  fetchData = async (stringQuery) => {
    let res = await Promise.all([
      portalService.GET_WALLET_HISTORY_LIST(stringQuery),
      portalService.GET_WALLET_LIST(`?pagination=false`),
    ]);
    // let isErr = false;
    let reduceData = this.renderSummaryGroup(res[0].data.docs).reduce(
      (result, data) => {
        let find = res[1].data.docs.find((e) => e._id === data.wallet_ref_id);
        if (find) {
          result.push({ ...data, wallet_detail: find });
        } else {
          // isErr = true;
        }
        return result;
      },
      []
    );
    if (res) {
      this.setState({
        data: reduceData,
        isLoading: false,
        pagination: {
          last_page: res[0].data.totalPages,
          current_page: res[0].data.page,
        },
      });
    }
  };

  renderSummaryGroup = (e) => {
    if (!e) {
      return e;
    } else {
      let reduce = e.reduce((result, data, i) => {
        if (i === 0) {
          let params = data;
          if (data.status === "1") {
            params.receivedPointTotal = data.point;
          }
          if (data.status === "2") {
            params.redeemPointTotal = data.point;
          }
          if (data.status === "5") {
            params.pointAfterAdd = data.point;
          }
          if (data.status === "6") {
            params.systemError = data.point;
          }
          result.push(params);
        } else {
          let find = result.findIndex(
            (f) => f.customer_ref_id === data.customer_ref_id
          );
          if (find === -1) {
            let params = data;
            if (data.status === "1") {
              params.receivedPointTotal = data.point;
            }
            if (data.status === "2") {
              params.redeemPointTotal = data.point;
            }
            if (data.status === "5") {
              params.pointAfterAdd = data.point;
            }
            if (data.status === "6") {
              params.systemError = data.point;
            }
            result.push(params);
          } else {
            if (data.status === "1") {
              result[find].receivedPointTotal = result[find].receivedPointTotal
                ? result[find].receivedPointTotal + data.point
                : data.point;
            }
            if (data.status === "2") {
              result[find].redeemPointTotal = result[find].redeemPointTotal
                ? result[find].redeemPointTotal + data.point
                : data.point;
            }
            if (data.status === "5") {
              result[find].pointAfterAdd = result[find].pointAfterAdd
                ? result[find].pointAfterAdd + data.point
                : data.point;
            }
            if (data.status === "6") {
              result[find].systemError = result[find].systemError
                ? result[find].systemError + data.point
                : data.point;
            }
          }
        }
        return result;
      }, []);
      return reduce;
    }
  };

  handleFilterKeyword = (e) => {
    const { perPage } = this.state;
    this.setState({
      filterQuery: e,
    });
    this.handleJoinQuery(e, perPage, 1);
  };

  handlePageClick = (e) => {
    const { filterQuery, perPage } = this.state;
    this.handleJoinQuery(filterQuery, perPage, e.selected + 1);
  };

  handleJoinQuery = (query, per_page, page) => {
    let joinQuery = query ? `${query}&pagination=false` : `?pagination=false`;
    this.setState({
      isLoading: true,
    });
    this.fetchData(joinQuery);
  };

  handleExport = async () => {
    const { filterQuery } = this.state;
    this.setState({
      isBgLoading: true,
    });
    let joinQuery = filterQuery
      ? `${filterQuery}&pagination=false`
      : `?pagination=false`;
    // let res = await portalService.GET_WALLET_HISTORY_LIST(joinQuery);
    let res = await Promise.all([
      portalService.GET_WALLET_HISTORY_LIST(joinQuery),
      portalService.GET_WALLET_LIST(`?pagination=false`),
    ]);
    if (res) {
      var wb = XLSX.utils.book_new();
      var ws_name = "SheetJS";
      var ws_data = this.renderSummaryGroup(res[0].data.docs).reduce(
        (result, e, i) => {
          let find = res[1].data.docs.find((f) => f._id === e.wallet_ref_id);
          result.push([
            i + 1,
            `${e.customer_user_id}`,
            moment(e.created_at)
              .tz("Asia/Bangkok")
              .format("DD/MM/YYYY HH:mm:ss"),
            `${e.customer_cu_id}`,
            RenderCommaMoney(Number(e.receivedPointTotal), true),
            RenderCommaMoney(Number(e.redeemPointTotal), true),
            RenderCommaMoney(
              Number(e.receivedPointTotal - (e.redeemPointTotal || 0)),
              true
            ),
            !find
              ? "ผิดพลาด"
              : RenderCommaMoney(Number(find.point ? find.point : 0), true),
            // RenderCommaMoney(
            //   e.receivedPointTotal + (e.pointAfterAdd ? e.pointAfterAdd : 0)
            // ),
          ]);
          return result;
        },
        []
      );

      ws_data.splice(0, 0, [
        "No",
        "Login ID",
        "Created at",
        "CU code",
        "Total received point",
        "Redeem point",
        "Remaining point",
        "Point in system",
        // "Point after add",
      ]);
      var ws = XLSX.utils.aoa_to_sheet(ws_data);
      var wscols = [
        { wch: 5 },
        { wch: 35 },
        { wch: 25 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        // { wch: 15 },
      ];
      ws["!cols"] = wscols;
      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(
        wb,
        `User point report ${moment()
          .tz("Asia/Bangkok")
          .format("DD/MM/YYYY")}.xlsx`
      );
      this.setState({
        isBgLoading: false,
      });
    }
  };

  handleChangeOutputType = (e) => {
    this.setState({
      outputTypeCurrent: e,
    });
  };

  render() {
    const { isLoading, isBgLoading, pagination, data } = this.state;
    return (
      <UserPointContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        <div className="filter_layout">
          <Inputs.InputFilter
            theme_standard_input_filter
            dataFilter={dataFilter}
            btnLabel="ค้นหา"
            onQueryFilter={this.handleFilterKeyword}
            onExport={this.handleExport}
          />
        </div>
        {isLoading ? (
          <Displays.Loading theme_standard_loading heightLayout="418px" />
        ) : (
          <Tables.Standard
            columns={columns}
            data={data}
            showPagination
            pagination={pagination}
            handlePageClick={this.handlePageClick}
          />
        )}
      </UserPointContainerStyled>
    );
  }
}

const dataFilter = [
  {
    accessor: "user_id",
    length: "311px",
    filter: {
      fieldQuery: "user_id",
      type: "text",
      label: "Select User : ",
      placeholder: "User ID",
    },
  },
  {
    accessor: "date",
    filter: {
      fieldQuery: "date",
      type: "dateRank",
      label: "Date Range : ",
    },
  },
];

const columns = [
  {
    Header: <div style={{ textAlign: "left" }}>Login ID</div>,
    accessor: "customer_user_id",
  },
  {
    Header: <div style={{ textAlign: "left" }}>Created at</div>,
    accessor: "created_at",
    Cell: (props) =>
      moment(props.value).tz("Asia/Bangkok").format("DD/MM/YYYY HH:mm:ss"),
  },
  {
    Header: <div style={{ textAlign: "left" }}>CU code</div>,
    accessor: "customer_cu_id",
  },
  {
    Header: <div style={{ textAlign: "center" }}>Total received point</div>,
    accessor: "receivedPointTotal",
    Cell: (props) => (
      <div style={{ textAlign: "center" }}>
        {RenderCommaMoney(Number(props.value), true)}
      </div>
    ),
  },
  {
    Header: "Redeem point",
    accessor: "redeemPointTotal",
    Cell: (props) => (
      <div style={{ textAlign: "center" }}>
        {RenderCommaMoney(Number(props.value), true)}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: "right" }}>Remaining point</div>,
    accessor: "remaining_point",
    Cell: (props) => (
      <div style={{ textAlign: "right" }}>
        {RenderCommaMoney(
          Number(
            props.row.original.receivedPointTotal -
              (props.row.original.redeemPointTotal || 0)
          ),
          true
        )}
      </div>
    ),
  },
  {
    Header: "Point in system",
    accessor: "wallet_detail",
    Cell: (props) => (
      <div style={{ textAlign: "center" }}>
        {RenderCommaMoney(Number(props.value?.point), true)}
      </div>
    ),
  },
  {
    Header: "System error",
    accessor: "systemError",
    Cell: (props) => (
      <div style={{ textAlign: "center" }}>
        {RenderCommaMoney(Number(-props.value), true)}
      </div>
    ),
  },
];

export default withRouter(UserPointContainer);
