import React from 'react';
import { UserInterFaceContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
// import { ROUTE_PATH } from 'utils/constants/routePath';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';
import { portalService } from 'apiServices';
import { toast } from 'react-toastify';
import { Forms, Displays } from 'components';

class UserInterFaceContainer extends React.Component {
  state = { isLoading: true };

  componentDidMount() {
    scrollToTopPageFN();
    this.fetchData();
  }

  fetchData = async () => {
    let res = await portalService.GET_CONFIG_LIST();
    if (res && res.status === 200) {
      this.setState({
        data: res.data.docs[0],
        initialValues: res.data.docs.length > 0 ? { ...res.data.docs[0] } : {},
        isLoading: false,
      });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleSubmit = async (values) => {
    this.setState({
      isBgLoading: true,
    });
    let filesTemp = [
      ...values.header_img.filter((e) => e.isNewUpload || e.isDelete),
      ...values.header_bg_img.filter((e) => e.isNewUpload || e.isDelete),
      ...values.footer_bg_img.filter((e) => e.isNewUpload || e.isDelete),
      ...values.bg_img.filter((e) => e.isNewUpload || e.isDelete),
      ...values.menu1_img.filter((e) => e.isNewUpload || e.isDelete),
      ...values.menu1_active_img.filter((e) => e.isNewUpload || e.isDelete),
      ...values.menu2_img.filter((e) => e.isNewUpload || e.isDelete),
      ...values.menu2_active_img.filter((e) => e.isNewUpload || e.isDelete),
      ...values.menu3_img.filter((e) => e.isNewUpload || e.isDelete),
      ...values.menu3_active_img.filter((e) => e.isNewUpload || e.isDelete),
      ...values.menu4_img.filter((e) => e.isNewUpload || e.isDelete),
      ...values.menu4_active_img.filter((e) => e.isNewUpload || e.isDelete),
    ];
    if (filesTemp && filesTemp.length > 0) {
      this.handleUploadContentMedia(filesTemp);
    } else {
      this.setState({
        isBgLoading: false,
      });
      toast.error('ไม่มีการอัพเดตใหม่');
    }
  };

  handleUploadContentMedia = (files) => {
    const { data } = this.state;
    Promise.all(
      files.map((e) => {
        return new Promise(async (resolve) => {
          if (e.isNewUpload) {
            const bodyFormData = new FormData();
            bodyFormData.set('type', e.fieldName);
            bodyFormData.append('image', e);
            let res = await portalService.POST_UPLOAD_CONFIG(bodyFormData);
            if (res && res.status === 200) {
              resolve();
            }
          }
          if (e.isDelete) {
            let res = await portalService.POST_UPLOAD_DELETE({
              config_id: data._id,
              type: e.fieldName,
              img_path: e.media_uri,
            });
            if (res && res.status === 200) {
              resolve();
            }
          }
        });
      })
    ).then(() => {
      this.setState({
        isBgLoading: false,
      });
      toast.success('อัพเดจสำเร็จ');
      this.fetchData();
    });
  };

  render() {
    const { isLoading, isBgLoading, initialValues } = this.state;
    return (
      <UserInterFaceContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {isLoading ? (
          <Displays.Loading theme_standard_loading heightLayout="245px" />
        ) : (
          <Forms.UserInterfaceForm
            initialValues={initialValues}
            onSubmit={this.handleSubmit}
          />
        )}
      </UserInterFaceContainerStyled>
    );
  }
}

export default withRouter(UserInterFaceContainer);
