import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { CofirmBoxStyled } from './styled';
import { Buttons } from 'components'

const CofirmBox = ({ theme_standard, label, labelBtn1, labelBtn2, onAction1, onAction2 }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <CofirmBoxStyled>
      <div className={customClass}>
        <div className="con_box">
          <div>
            {label}
          </div>
          <div className='btn_zone'>
            <div className='btn_box'>
              <Buttons.BgStandard label={labelBtn1} onClick={onAction1} />
            </div>
            <div className='btn_box'>
              <Buttons.BgStandard theme_gray label={labelBtn2} onClick={onAction2} />
            </div>
          </div>
        </div>
      </div>
    </CofirmBoxStyled>
  );
};

CofirmBox.propTypes = {};

export default CofirmBox;
