import React, { useState } from 'react';
import cx from 'classnames';
import { InputFilterStyled } from './styled';
import moment from 'moment';
import { Icons, Inputs, Buttons } from 'components';

const InputFilter = ({
  theme_standard_input_filter,
  dataFilter,
  onQueryFilter,
  btnLabel,
  onExport,
}) => {
  const customClass = cx({
    theme_standard_input_filter: theme_standard_input_filter,
  });

  const [_filter, _setFilter] = useState({});

  // useEffect(() => {
  //   if (Object.keys(_filter).length === 0) {
  //   } else {
  //     let queryArr = [];
  //     let query = '';
  //     Object.keys(_filter).forEach((key) => {
  //       if (_filter[key].value) {
  //         queryArr.push(`${_filter[key].fieldQuery}=${_filter[key].value}`);
  //       }
  //     });
  //     if (queryArr.length > 0) {
  //       query = `?${queryArr.join('&')}`;
  //     }
  //     onQueryFilter && onQueryFilter(query);
  //   }
  // }, [_filter]); // eslint-disable-line react-hooks/exhaustive-deps

  const clickFilter = () => {
    if (Object.keys(_filter).length === 0) {
    } else {
      let queryArr = [];
      let query = '';
      Object.keys(_filter).forEach((key) => {
        if (_filter[key].value && _filter[key].type !== 'dateRank') {
          queryArr.push(`${_filter[key].fieldQuery}=${_filter[key].value}`);
        } else {
          queryArr.push(`${_filter[key].value}`);
        }
      });
      if (queryArr.length > 0) {
        query = `?${queryArr.join('&')}`;
      }
      onQueryFilter && onQueryFilter(query);
    }
  };

  const renderFilter = (e) => {
    switch (e.filter.type) {
      case 'text':
        return (
          <Inputs.TextField
            label={e.filter.label}
            placeholder={e.filter.placeholder && e.filter.placeholder}
            onChange={(f) => {
              _setFilter({
                ..._filter,
                [e.accessor]: {
                  fieldQuery: e.filter.fieldQuery,
                  name: e.accessor,
                  type: e.filter.type,
                  value: f.target.value,
                },
              });
            }}
          />
        );

      // case 'date':
      //   return (
      //     <Inputs.InputDatePikerCustom
      //       label={e.filter.label}
      //       theme_standard_date_picker_custom
      //       placeholder={e.filter.placeholder && e.filter.placeholder}
      //       onChange={(f) => {
      //         _setFilter({
      //           ..._filter,
      //           [e.accessor]: {
      //             fieldQuery: e.filter.fieldQuery,
      //             name: e.accessor,
      //             type: e.filter.type,
      //             value: momentTZ(f).format('YYYY-MM-DD'),
      //           },
      //         });
      //       }}
      //     />
      //   );

      case 'dropdown':
        return (
          <Inputs.Dropdown
            label={e.filter.label}
            iconSvgClose={<Icons.ArrowDropDown />}
            iconSvgOpen={<Icons.ArrowDropDown up />}
            placeholder={e.filter.placeholder}
            options={e.filter.options}
            onChange={(f) => {
              _setFilter({
                ..._filter,
                [e.accessor]: {
                  fieldQuery: e.filter.fieldQuery,
                  name: e.accessor,
                  type: e.filter.type,
                  value: f,
                },
              });
            }}
          />
        );

      // case 'label':
      //   return <div className={`box_label active`}>{e.labelName}</div>;

      case 'dateRank':
        return (
          <Inputs.InputDatePickerBetween
            label={e.filter.label}
            startDatePlaceholder="เริ่ม"
            endDatePlaceholder={'สิ้นสุด'}
            onChange={(f) => {
              _setFilter({
                ..._filter,
                [e.accessor]: {
                  fieldQuery: e.filter.fieldQuery,
                  name: e.accessor,
                  type: e.filter.type,
                  value: `start_date=${moment(f.startDate).format(
                    'YYYY-MM-DD'
                  )}&end_date=${moment(f.endDate).format('YYYY-MM-DD')}`,
                },
              });
            }}
          />
        );
      case 'multiSelect':
        return (
          <Inputs.InputMultiSelect
            label={e.filter.label}
            options={e.filter.options}
            placeholder={e.filter.placeholder && e.filter.placeholder}
            onChange={(f) => {
              let joinString = '';
              f.forEach((g, i) => {
                joinString += g.value;
                if (f.length - 1 !== i) {
                  joinString += ',';
                }
              });
              _setFilter({
                ..._filter,
                [e.accessor]: {
                  fieldQuery: e.filter.fieldQuery,
                  name: e.accessor,
                  type: e.filter.type,
                  value: joinString,
                },
              });
            }}
          />
        );
      default:
        break;
    }
  };

  return (
    <InputFilterStyled>
      <div className={customClass}>
        {dataFilter &&
          dataFilter.map((e, i) => (
            <div key={i}>
              <div key={i + 1} className="box_filter">
                {e.label && <div className="label_top">{e.label}</div>}
                <div key={i} style={{ width: `${e.length}` }}>
                  {renderFilter(e)}
                </div>
              </div>
            </div>
          ))}
        <div className="btn_action_filter">
          <Buttons.BgStandard
            label={btnLabel}
            height="100%"
            onClick={clickFilter}
          />
        </div>
        {onExport && (
          <div className="btn_action_filter">
            <Buttons.BgStandard
              theme_export_btn
              label={'Export'}
              height="100%"
              onClick={onExport}
            />
          </div>
        )}
      </div>
    </InputFilterStyled>
  );
};

InputFilter.propTypes = {};

export default InputFilter;
