import { SystemLayoutStyled } from './styled';
import { Outlet } from 'react-router-dom';
import { NavbarWidget, SidebarWidget } from 'widgets';

const SystemLayout = () => {
  return (
    <SystemLayoutStyled>
      <div className="left_mainlayout">
        <SidebarWidget />
      </div>
      <div className="right_mainlayout">
        <NavbarWidget />
        <div className="body_mainlayout">
          <Outlet />
        </div>
      </div>
    </SystemLayoutStyled>
  );
};

export default SystemLayout;
