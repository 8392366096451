import React from "react";
import { UserPointHistoryContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { portalService } from "apiServices";
import moment from "moment";
import { RenderCommaMoney } from "utils/functions/FormatMoney";
import { Displays, Tables } from "components";

class UserPointHistoryContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { cusID } = this.props;
    let res = await portalService.GET_CUSTOMER_UPLOADED_POINT_LIST(cusID);
    if (res && res.status === 200) {
      this.setState({
        data: res.data,
        isLoading: false,
      });
    }
  };

  render() {
    const { point = 0 } = this.props;
    const { isLoading, data } = this.state;
    return (
      <UserPointHistoryContainerStyled>
        {isLoading ? (
          <Displays.Loading theme_standard_loading />
        ) : (
          <div className="phone_layout">
            <img alt="phone" src="/assets/images/icons/phone.png" />
            <div className="phone_body_container">
              <div
                className="p_header"
                // style={
                //   _preview.header_bg_img && {
                //     backgroundImage: `url(${_preview.header_bg_img})`,
                //   }
                // }
              >
                <div className="header_row">
                  <div className="header_left_col">
                    <div className="nav_logo_wrap">
                      {/* {_preview.header_img && (
                        <img
                          className="nav_logo"
                          alt="misumi logo"
                          src={_preview.header_img}
                        />
                      )} */}
                    </div>
                  </div>
                  <div className="header_right_col">
                    <div className="nav_point">
                      <div className="nav_p_label">
                        <div className="nav_point_label">คะแนนของคุณ</div>
                      </div>
                      {point}
                      <img
                        className="coin_img"
                        alt="coin"
                        src="/assets/images/icons/coin.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="p_body"
                // style={
                //   _preview.bg_img && {
                //     backgroundImage: `url(${_preview.bg_img})`,
                //   }
                // }
              >
                <Tables.HeaderRowPanel columns={columns()} data={data || []} />
              </div>
              <div
                className="p_menu_bottom"
                // style={
                //   _preview.footer_bg_img && {
                //     backgroundImage: `url(${_preview.footer_bg_img})`,
                //   }
                // }
              >
                <div className={`menu_wrap`}>
                  <div className="menu_item">
                    <div className="menu_img_figture">
                      {/* {_preview.menu1_img && (
                        <img
                          className="menu_img"
                          alt="menu"
                          src={_preview.menu1_img}
                        />
                      )} */}
                    </div>
                    <div className="mi_label">กิจกรรม</div>
                  </div>
                </div>
                <div className={`menu_wrap`}>
                  <div className="menu_item">
                    <div className="menu_img_figture">
                      {/* {_preview.menu2_img && (
                        <img
                          className="menu_img"
                          alt="menu"
                          src={_preview.menu2_img}
                        />
                      )} */}
                    </div>
                    <div className="mi_label">ของรางวัล</div>
                  </div>
                </div>
                <div className={`menu_wrap`}>
                  <div className="menu_item">
                    <div className="menu_img_figture">
                      {/* {_preview.menu3_img && (
                        <img
                          className="menu_img"
                          alt="menu"
                          src={_preview.menu3_img}
                        />
                      )} */}
                    </div>
                    <div className="mi_label">ประวัติ</div>
                  </div>
                </div>
                <div className={`menu_wrap`}>
                  <div className="menu_item">
                    <div className="menu_img_figture">
                      {/* {_preview.menu4_img && (
                        <img
                          className="menu_img"
                          alt="menu"
                          src={_preview.menu4_img}
                        />
                      )} */}
                    </div>
                    <div className="mi_label">โปรไฟล์</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </UserPointHistoryContainerStyled>
    );
  }
}

const columns = () => [
  {
    title: "วันที่",
    field: "created_at",
    headerStyle: { textAlign: "center" },
    render: ({ origin, value }) => (
      <div style={{ textAlign: "center" }}>
        {moment(value).format("DD/MM/YYYY")}
      </div>
    ),
  },
  {
    title: "กิจกรรม",
    field: "campaign_code",
  },
  {
    title: "ยอดสั่งซื้อ",
    field: "so_amount",
    render: ({ origin, value }) => RenderCommaMoney(value),
  },
  {
    title: "คะแนนที่ได้รับ",
    field: "point",
    style: { textAlign: "center" },
    render: ({ origin, value }) =>
      value === 0
        ? value
        : origin.status === "5"
        ? RenderCommaMoney(-value)
        : RenderCommaMoney(value),
  },
];
// const columns = [
//   {
//     Header: <div style={{ textAlign: "left" }}>วันที่</div>,
//     accessor: "created_at",
//     Cell: (props) => (
//       <div style={{ textAlign: "left" }}>
//         {moment(props.value).format("DD/MM/YYYY")}
//       </div>
//     ),
//   },
//   {
//     Header: <div style={{ textAlign: "left" }}>กิจกรรม</div>,
//     accessor: "campaign_code",
//   },
//   {
//     Header: <div style={{ textAlign: "left" }}>ยอดสั่งซื้อ</div>,
//     accessor: "so_amount",
//     render: ({ origin, value }) => RenderCommaMoney(value),
//   },
//   {
//     Header: <div style={{ textAlign: "right" }}>คะแนนที่ได้รับ</div>,
//     accessor: "point",
//     Cell: (props) => (
//       <div style={{ textAlign: "right" }}>
//         {props.value === 0
//           ? props.value
//           : props.row.original.status === "5"
//           ? RenderCommaMoney(-props.value)
//           : RenderCommaMoney(props.value)}
//       </div>
//     ),
//   },
// ];
export default withRouter(UserPointHistoryContainer);
