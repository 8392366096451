import styled from 'styled-components';

export const RewardContainerStyled = styled.div`
  .input_upload_wrap {
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
    .upload_block {
      max-width: 300px;
      width: 100%;
    }
    .btn_action_filter {
      height: 100%;
      width: 147px;
      flex-shrink: 0;
    }
    .btn_upload_ecoupon {
      cursor: pointer;
      color: transparent;
    }
  }
`;
