import React from 'react';
import { BannerContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';
import { portalService } from 'apiServices';
import moment from 'moment';
import 'moment-timezone';
import { MdAdd } from 'react-icons/md';
import { Buttons, Inputs, Displays, Tables } from 'components';

class BannerContainer extends React.Component {
  state = { isLoading: true, pagination: null, filterQuery: null, perPage: 10 };

  componentDidMount() {
    const { perPage } = this.state;
    scrollToTopPageFN();
    this.fetchData(`?limit=${perPage}`);
  }

  fetchData = async (stringQuery) => {
    let res = await portalService.GET_BANNER_LIST(stringQuery);
    if (res && res.status === 200) {
      this.setState({
        data: res.data.docs,
        isLoading: false,
        pagination: {
          last_page: res.data.totalPages,
          current_page: res.data.page,
        },
      });
    }
  };

  handleFilterKeyword = (e) => {
    const { perPage } = this.state;
    this.setState({
      filterQuery: e,
    });
    this.handleJoinQuery(e, perPage, 1);
  };

  handleClickCreate = (e) => {
    this.props.router.navigate(`${ROUTE_PATH.BANNER_DATA}/${e}`);
  };

  handlePageClick = (e) => {
    const { filterQuery, perPage } = this.state;
    this.handleJoinQuery(filterQuery, perPage, e.selected + 1);
  };

  handleJoinQuery = (query, per_page, page) => {
    let joinQuery = query
      ? `${query}&limit=${per_page}&page=${page}`
      : `?limit=${per_page}&page=${page}`;
    this.setState({
      isLoading: true,
    });
    this.fetchData(joinQuery);
  };

  render() {
    const { isLoading, pagination, data } = this.state;
    return (
      <BannerContainerStyled>
        <div className="btn_create">
          <div className="w120">
            <Buttons.BgIconPrefix
              label="New"
              icon={<MdAdd size={18} />}
              onClick={() => this.handleClickCreate('create')}
            />
          </div>
        </div>
        <Inputs.InputFilter
          theme_standard_input_filter
          dataFilter={dataFilter}
          btnLabel="ค้นหา"
          onQueryFilter={this.handleFilterKeyword}
        />
        {isLoading ? (
          <Displays.Loading theme_standard_loading heightLayout="324px" />
        ) : (
          <>
            <div className="body_table  ">
              <Tables.Standard
                columns={columns({ handleClickDetail: this.handleClickCreate })}
                data={data}
                showPagination
                pagination={pagination}
                handlePageClick={this.handlePageClick}
              />
            </div>
          </>
        )}
      </BannerContainerStyled>
    );
  }
}

const dataFilter = [
  {
    accessor: 'name',
    length: '311px',
    filter: {
      fieldQuery: 'name',
      type: 'text',
      label: 'Banner search',
      placeholder: 'Banner name',
    },
  },
];

const columns = ({ handleClickDetail }) => [
  {
    Header: <div style={{ textAlign: 'left' }}>Publication Date</div>,
    accessor: 'created_at',
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {moment(props.value).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm')}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Banner name</div>,
    accessor: 'name',
  },
  {
    Header: 'status',
    accessor: 'is_enabled',
    Cell: (props) => (
      <div
        className="body_status"
        onClick={() => handleClickDetail(props.row.original._id)}
      >
        <div
          className="round"
          style={{ background: props.value === true ? 'green' : 'red' }}
        />
      </div>
    ),
  },
];

export default withRouter(BannerContainer);
