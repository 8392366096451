import styled from 'styled-components';

export const ComponentContainerStyled = styled.div`
    .body_container {
        padding: 50px;
        display: flex;
        flex-direction: column;
        row-gap: 30px;
        .box1 {
            width: 50%;
        }
    }
`;
