export const ROUTE_PATH = {
  ROOT: '/',
  LOGIN: '/login',
  COMPONENTS: '/components',
  SYSTEM: '/system',
  DASHBOARD: '/system/dashboard',
  CAMPAIGN: '/system/campaign',
  CAMPAIGN_USER_INTERFACE: '/system/campaign/user-interface',
  CAMPAIGN_DATA: '/system/campaign/data',
  CAMPAIGN_DATA_DETAIL: '/system/campaign/data/:id',
  BANNER_DATA: '/system/campaign/banner/data',
  BANNER_DATA_DETAIL: '/system/campaign/banner/data/:id',
  APP_INFORMATION: '/system/app-infomation',
  APP_INFORMATION_REGISTRATION: '/system/app-infomation/registration',
  APP_INFORMATION_INFORMATION: '/system/app-infomation/information',
  APP_INFORMATION_DELIVERY: '/system/app-infomation/delivery',
  EXPORT_DATA: '/system/export-data',
  EXPORT_DATA_USER_PROFILE: '/system/export-data/user-profile',
  EXPORT_DATA_CAMPAIGN: '/system/export-data/campaign',
  EXPORT_DATA_USER_POINT: '/system/export-data/user-point',
  EXPORT_DATA_REDEEN_POINT: '/system/export-data/redeem-point',
  EXPORT_DATA_UPLOADED_POINT: '/system/export-data/uploaded-point',
  UPLOAD_DATA: '/system/upload-data',
  UPLOAD_DATA_CU_POINT: '/system/upload-data/cu-point',
  UPLOAD_DATA_REWARD: '/system/upload-data/reward',
  REWARD: '/system/reward',
  REWARD_POINT: '/system/reward/reward-point',
  REWARD_POINT_DETAIL: '/system/reward/reward-point/data',
  REWARD_POINT_DETAIL_BY_ID: '/system/reward/reward-point/data/:id',
};
