import { ROUTE_PATH } from 'utils/constants/routePath';
import theme from 'styles/theme.json';
import TopTab from 'components/Navigations/TopTab';
import {
  // MdDashboard,
  MdDownloadForOffline,
  MdCloudUpload,
  MdCampaign,
  MdOutlineAssignment,
} from 'react-icons/md';
import { AiFillGift } from 'react-icons/ai';
// import DashboardContainer from 'containers/DashboardSystem/DashboardContainer';
import UserInterFaceContainer from 'containers/CampaignSystem/UserInterFaceSystem/UserInterFaceContainer';
import CampaignContainer from 'containers/CampaignSystem/CampaignFlowSystem/CampaignContainer';
import CampaignAddEditContainer from 'containers/CampaignSystem/CampaignFlowSystem/CampaignAddEditContainer';
import CampaignExportContainer from 'containers/ExportDataSystem/CampaignExportContainer';
import BannerContainer from 'containers/CampaignSystem/BannerSystem/BannerContainer';
import BannerAddEditContainer from 'containers/CampaignSystem/BannerSystem/BannerAddEditContainer';
import RedeemPointContainer from 'containers/ExportDataSystem/RedeemPointContainer';
import UploadedPointContainer from 'containers/ExportDataSystem/UploadedPointContainer';
import UserPointContainer from 'containers/ExportDataSystem/UserPointContainer';
import UserProfileContainer from 'containers/ExportDataSystem/UserProfileContainer';
import RegistrationContainer from 'containers/InformationSystem/RegistrationSystem/RegistrationContainer';
import InformationContainer from 'containers/InformationSystem/InformationFlowSystem/InformationContainer';
// import DeliveryContainer from 'containers/InformationSystem/DeliverySystem/DeliveryContainer';
import CUPointContainer from 'containers/UploadDataSystem/CUPointContainer';
import RewardContainer from 'containers/UploadDataSystem/RewardContainer';
import RewardPointContainer from 'containers/RewardSystem/RewardPointFlowSystem/RewardPointContainer';
import RewardAddEditContainer from 'containers/RewardSystem/RewardPointFlowSystem/RewardAddEditContainer';

const systemRoutes = [
  // {
  //   key: 'dashboard',
  //   name: 'Dashboard',
  //   path: ROUTE_PATH.DASHBOARD,
  //   icon: (active) => (
  //     <MdDashboard
  //       size={28}
  //       color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2}
  //     />
  //   ),
  //   element: <DashboardContainer />,
  // },
  {
    key: 'campaign',
    name: 'Campaign',
    path: ROUTE_PATH.CAMPAIGN,
    icon: (active) => (
      <MdCampaign
        size={28}
        color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2}
      />
    ),
    element: (
      <TopTab
        data={[
          {
            key: 'user_interface',
            name: 'User interface',
            path: ROUTE_PATH.CAMPAIGN_USER_INTERFACE,
            element: <UserInterFaceContainer />,
          },
          {
            key: 'campaign_data',
            name: 'Campaign data',
            path: ROUTE_PATH.CAMPAIGN_DATA,
            element: <CampaignContainer />,
          },
          {
            key: 'banner_data',
            name: 'Banner',
            path: ROUTE_PATH.BANNER_DATA,
            element: <BannerContainer />,
          },
        ]}
      />
    ),
    children: [
      {
        key: 'user_interface',
        name: 'User interface',
        path: ROUTE_PATH.CAMPAIGN_USER_INTERFACE,
        element: <UserInterFaceContainer />,
      },
      {
        key: 'campaign_data',
        name: 'Campaign data',
        path: ROUTE_PATH.CAMPAIGN_DATA,
        element: <CampaignContainer />,
      },
      {
        key: 'user_interface_data',
        name: 'User Interface data',
        path: ROUTE_PATH.CAMPAIGN_DATA_DETAIL,
        isSubMenu: true,
        element: <CampaignAddEditContainer />,
      },
      {
        key: 'banner_data',
        name: 'Banner',
        path: ROUTE_PATH.BANNER_DATA,
        element: <BannerContainer />,
      },
      {
        key: 'banner_data',
        name: 'Banner',
        path: ROUTE_PATH.BANNER_DATA_DETAIL,
        isSubMenu: true,
        element: <BannerAddEditContainer />,
      },
    ],
  },
  {
    key: 'app-infomation',
    name: 'Information',
    path: ROUTE_PATH.APP_INFORMATION,
    icon: (active) => (
      <MdOutlineAssignment
        size={28}
        color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2}
      />
    ),
    element: (
      <TopTab
        data={[
          {
            key: 'registration',
            name: 'Registration',
            path: ROUTE_PATH.APP_INFORMATION_REGISTRATION,
            element: <RegistrationContainer />,
          },
          {
            key: 'information',
            name: 'Information',
            path: ROUTE_PATH.APP_INFORMATION_INFORMATION,
            element: <InformationContainer />,
          },
          // {
          //   key: 'delivery',
          //   name: 'Delivery',
          //   path: ROUTE_PATH.APP_INFORMATION_DELIVERY,
          //   element: <DeliveryContainer />,
          // },
        ]}
      />
    ),
    children: [
      {
        key: 'refistration',
        name: 'Refistration',
        path: ROUTE_PATH.APP_INFORMATION_REGISTRATION,
        element: <RegistrationContainer />,
      },
      {
        key: 'information',
        name: 'Information',
        path: ROUTE_PATH.APP_INFORMATION_INFORMATION,
        element: <InformationContainer />,
      },
      // {
      //   key: 'delivery',
      //   name: 'Delivery',
      //   path: ROUTE_PATH.APP_INFORMATION_DELIVERY,
      //   element: <DeliveryContainer />,
      // },
    ],
  },
  {
    key: 'export-data',
    name: 'Export data',
    path: ROUTE_PATH.EXPORT_DATA,
    icon: (active) => (
      <MdDownloadForOffline
        size={28}
        color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2}
      />
    ),
    element: (
      <TopTab
        data={[
          {
            key: 'user_profile',
            name: 'User profile',
            path: ROUTE_PATH.EXPORT_DATA_USER_PROFILE,
            element: <UserProfileContainer />,
          },
          {
            key: 'campaign_export',
            name: 'Campaign',
            path: ROUTE_PATH.EXPORT_DATA_CAMPAIGN,
            element: <CampaignExportContainer />,
          },
          {
            key: 'user_point',
            name: 'User point',
            path: ROUTE_PATH.EXPORT_DATA_USER_POINT,
            element: <UserPointContainer />,
          },
          {
            key: 'uploaded point',
            name: 'Uploaded point',
            path: ROUTE_PATH.EXPORT_DATA_UPLOADED_POINT,
            element: <UploadedPointContainer />,
          },
          {
            key: 'redeem_point',
            name: 'Redeem point',
            path: ROUTE_PATH.EXPORT_DATA_REDEEN_POINT,
            element: <RedeemPointContainer />,
          },
        ]}
      />
    ),
    children: [
      {
        key: 'user_profile',
        name: 'User profile',
        path: ROUTE_PATH.EXPORT_DATA_USER_PROFILE,
        element: <UserProfileContainer />,
      },
      {
        key: 'campaign_export',
        name: 'Campaign',
        path: ROUTE_PATH.EXPORT_DATA_CAMPAIGN,
        element: <CampaignExportContainer />,
      },
      {
        key: 'user_point',
        name: 'User point',
        path: ROUTE_PATH.EXPORT_DATA_USER_POINT,
        element: <UserPointContainer />,
      },
      {
        key: 'uploaded point',
        name: 'Uploaded point',
        path: ROUTE_PATH.EXPORT_DATA_UPLOADED_POINT,
        element: <UploadedPointContainer />,
      },
      {
        key: 'redeem_point',
        name: 'Redeem point',
        path: ROUTE_PATH.EXPORT_DATA_REDEEN_POINT,
        element: <RedeemPointContainer />,
      },
    ],
  },
  {
    key: 'upload-data',
    name: 'Upload point',
    path: ROUTE_PATH.UPLOAD_DATA,
    icon: (active) => (
      <MdCloudUpload
        size={28}
        color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2}
      />
    ),
    element: (
      <TopTab
        data={[
          {
            key: 'upload_data_cu_point',
            name: 'Upload CU point',
            path: ROUTE_PATH.UPLOAD_DATA_CU_POINT,
            element: <CUPointContainer />,
          },
          {
            key: 'upload_rewards',
            name: 'Upload rewards',
            path: ROUTE_PATH.UPLOAD_DATA_REWARD,
            element: <RewardContainer />,
          },
        ]}
      />
    ),
    children: [
      {
        key: 'upload_data_cu_point',
        name: 'Upload CU point',
        path: ROUTE_PATH.UPLOAD_DATA_CU_POINT,
        element: <CUPointContainer />,
      },
      {
        key: 'upload_rewards',
        name: 'Upload rewards',
        path: ROUTE_PATH.UPLOAD_DATA_REWARD,
        element: <RewardContainer />,
      },
    ],
  },
  {
    key: 'reward',
    name: 'Reward',
    path: ROUTE_PATH.REWARD,
    icon: (active) => (
      <AiFillGift
        size={28}
        color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2}
      />
    ),
    element: (
      <TopTab
        data={[
          {
            key: 'reward_point',
            name: 'Reward',
            path: ROUTE_PATH.REWARD_POINT,
            element: <RewardPointContainer />,
          },
        ]}
      />
    ),
    children: [
      {
        key: 'reward_point',
        name: 'Reward',
        path: ROUTE_PATH.REWARD_POINT,
        element: <RewardPointContainer />,
      },
      {
        key: 'reward_point_data',
        name: 'Reward point data',
        path: ROUTE_PATH.REWARD_POINT_DETAIL_BY_ID,
        isSubMenu: true,
        element: <RewardAddEditContainer />,
      },
    ],
  },
];

export default systemRoutes;
