import styled from 'styled-components';

export const NavbarWidgetStyled = styled.div`
  padding: 0 3rem;
  height: 77px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .nav_user_infomation {
  }
`;
