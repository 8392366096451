export const RENDER_STATUS = (key) => {
  switch (key) {
    case "1":
      return "สำเร็จ";
    case "2":
      return "ยกเลิก";
    case "3":
      return "รออนุมัติ";
    case "4":
      return "ไม่สำเร็จ";
    default:
      break;
  }
};
