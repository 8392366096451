import React from "react";
import { CUPointContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import moment from "moment";
import { portalService } from "apiServices";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import { toast } from "react-toastify";
import { RenderCommaMoney } from "utils/functions/FormatMoney";
import { RENDER_STATUS } from "utils/functions/transaction";
import { Buttons, Inputs, Tables, Displays } from "components";
import { AlertControl } from "components/Modals/Alert";

class CUPointContainer extends React.Component {
  state = {};

  componentDidMount() {
    scrollToTopPageFN();
  }

  handleChangeFile = (e) => {
    this.setState({
      fileCurrent: e,
    });
  };

  handleUpload = async () => {
    const { fileCurrent } = this.state;
    this.setState({
      isBgLoading: true,
    });
    if (!fileCurrent) {
      toast.error(`กรุณาอัพโหลดไฟล์`);
    } else {
      const bodyFormData = new FormData();
      bodyFormData.append("file", fileCurrent[0]);
      let res = await portalService.POST_IMPORT_POINT(bodyFormData);
      if (res && res.status === 200) {
        this.setState({
          isSuccess: true,
          isBgLoading: false,
          dataUploadPoint: res.data.uploadCuPoint,
          dataUserPoint: res.data.walletHistory,
          fileCurrent: false,
        });
        toast.success("ดราฟข้อมูลสำเร็จ");
      } else {
        this.setState({
          isSuccess: false,
          isBgLoading: false,
          data: res.data,
          fileCurrent: false,
        });
        toast.error(res.Message);
      }
    }
  };

  handleConfirm = async () => {
    const { dataUploadPoint, dataUserPoint } = this.state;
    if (dataUploadPoint.length !== dataUserPoint.length) {
      toast.error("ข้อมูลในการอัพมีปัญหา ไม่ควรอนุมัติ");
    } else {
      AlertControl.show({
        title: "ยืนยันการอนุมัติ",
        description: "คุณต้องการจะอนุมัติใช่หรือไม่",
        btnLabel1: "ยกเลิก",
        btnLabel2: "ยืนยัน",
        onEvent2: async () => {
          this.setState({
            isBgLoading: true,
          });
          let dataUploadPointTemp = dataUploadPoint;
          let dataUserPointTemp = dataUserPoint;
          let resProcess = await new Promise(async (resolve, reject) => {
            let results = [];
            try {
              const round = dataUploadPoint.length;
              for (let index = 0; index < round; index++) {
                let params = {
                  upload_point_id: dataUploadPoint[index]._id,
                  wallet_history_id: dataUserPoint[index]._id,
                  wallet_id: dataUserPoint[index].wallet_ref_id,
                };
                const res = await portalService.POST_WALLET_APPROVE(params);
                if (res && res.status === 200) {
                  dataUploadPointTemp[index] = res.data.uploadCuPoint;
                  dataUserPointTemp[index] = res.data.walletHistory;
                  this.setState({
                    dataUploadPoint: [...dataUploadPointTemp],
                    dataUserPoint: [...dataUserPointTemp],
                  });
                } else {
                  dataUploadPointTemp[index].status = "4";
                  dataUserPointTemp[index].status = "4";
                  this.setState({
                    dataUploadPoint: [...dataUploadPointTemp],
                    dataUserPoint: [...dataUserPointTemp],
                  });
                }
              }
              resolve(results);
            } catch (error) {}
          });
          if (resProcess) {
            this.setState({
              isBgLoading: false,
            });
            toast.success("อนุมัติสำเร็จ");
          } else {
            this.setState({
              isBgLoading: false,
            });
          }
        },
      });
    }
  };

  render() {
    const {
      isLoading,
      isBgLoading,
      data,
      fileCurrent,
      dataUploadPoint,
      dataUserPoint,
      isSuccess,
    } = this.state;
    return (
      <CUPointContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        <div className="input_upload_wrap">
          <div className="upload_block">
            <Inputs.UploadImage
              theme_upload_image
              value={fileCurrent}
              acceptType="csv"
              onChange={this.handleChangeFile}
            />
          </div>
          <div className="btn_action_filter">
            <Buttons.BgStandard label={"Upload"} onClick={this.handleUpload} />
          </div>
        </div>
        {isLoading ? (
          <Displays.Loading theme_standard_loading heightLayout="311px" />
        ) : !isSuccess ? (
          data && (
            <>
              <div className="table_wrap">
                <div className="tabale_title">ตาราง Match สำเร็จ</div>
                <Tables.Standard
                  columns={columns}
                  data={data?.dataMatch || []}
                />
              </div>
              <div className="table_wrap">
                <div className="tabale_title">ตาราง Match ไม่สำเร็จ</div>
                <Tables.Standard
                  columns={columns}
                  data={data?.dataNotMatch || []}
                />
              </div>
            </>
          )
        ) : (
          dataUploadPoint && (
            <>
              <div className="table_wrap">
                <div className="tabale_title">
                  ธุรกรรม Upload point (
                  {dataUploadPoint ? dataUploadPoint.length : 0})
                </div>
                <Tables.Standard
                  columns={columnsUploadPoint}
                  data={dataUploadPoint || []}
                />
              </div>
              <div className="table_wrap">
                <div className="tabale_title">
                  ธุรกรรม User point ({dataUserPoint ? dataUserPoint.length : 0}
                  )
                </div>
                <Tables.Standard
                  columns={columnsUserPoint}
                  data={dataUserPoint || []}
                />
              </div>
              <div className="btn_approve">
                <Buttons.BgStandard
                  label={"ยืนยันการอนุมัติ"}
                  onClick={this.handleConfirm}
                />
              </div>
            </>
          )
        )}
      </CUPointContainerStyled>
    );
  }
}

const columns = [
  {
    Header: <div style={{ textAlign: "left" }}>Upload date</div>,
    accessor: "Upload date",
    Cell: (props) => (
      <div>{moment(props.value, "YYYY-MM-DD").format("DD/MM/YYYY")}</div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Campaign code</div>,
    accessor: "Campaign code",
  },
  {
    Header: <div style={{ textAlign: "left" }}>Login ID</div>,
    accessor: "Log in ID",
  },
  {
    Header: <div style={{ textAlign: "left" }}>CU code</div>,
    accessor: "CU code",
  },
  {
    Header: <div style={{ textAlign: "left" }}>SO Amount</div>,
    accessor: "SO Amount",
  },
  {
    Header: <div style={{ textAlign: "right" }}>Update Point</div>,
    accessor: "Update Point",
    Cell: (props) => (
      <div style={{ textAlign: "right" }}>
        {RenderCommaMoney(Number(props.value), true)}
      </div>
    ),
  },
];

const columnsUploadPoint = [
  {
    Header: <div style={{ textAlign: "left" }}>Upload date</div>,
    accessor: "uploaded_at",
    Cell: (props) => (
      <div>{moment(props.value, "YYYY-MM-DD").format("DD/MM/YYYY")}</div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Customer id</div>,
    accessor: "customer_ref_id",
  },
  {
    Header: <div style={{ textAlign: "left" }}>Customer user id</div>,
    accessor: "customer_user_id",
  },
  {
    Header: <div style={{ textAlign: "left" }}>Customer cu id</div>,
    accessor: "customer_cu_id",
  },
  {
    Header: <div style={{ textAlign: "left" }}>Campaign code</div>,
    accessor: "campaign_code",
  },
  {
    Header: <div style={{ textAlign: "left" }}>So amount</div>,
    accessor: "so_amount",
  },
  {
    Header: <div style={{ textAlign: "left" }}>Point</div>,
    accessor: "point",
  },
  {
    Header: <div style={{ textAlign: "right" }}>Status</div>,
    accessor: "status",
    Cell: (props) => (
      <div style={{ textAlign: "right" }}>{RENDER_STATUS(props.value)}</div>
    ),
  },
];

const columnsUserPoint = [
  {
    Header: <div style={{ textAlign: "left" }}>Created date</div>,
    accessor: "created_at",
    Cell: (props) => (
      <div>{moment(props.value, "YYYY-MM-DD").format("DD/MM/YYYY")}</div>
    ),
  },
  {
    Header: <div style={{ textAlign: "left" }}>Customer id</div>,
    accessor: "customer_ref_id",
  },
  {
    Header: <div style={{ textAlign: "left" }}>Customer user id</div>,
    accessor: "customer_user_id",
  },
  {
    Header: <div style={{ textAlign: "left" }}>Customer cu id</div>,
    accessor: "customer_cu_id",
  },
  {
    Header: <div style={{ textAlign: "left" }}>Campaign code</div>,
    accessor: "campaign_code",
  },
  {
    Header: <div style={{ textAlign: "left" }}>Point</div>,
    accessor: "point",
  },
  // {
  //   Header: <div style={{ textAlign: "left" }}>Point in system</div>,
  //   accessor: "wallet_detail",
  //   Cell: (props) => props.value?.point,
  // },
  // {
  //   Header: <div style={{ textAlign: "left" }}>Total point</div>,
  //   accessor: "point_total",
  //   Cell: (props) =>
  //     props.row.original?.wallet_detail
  //       ? props.row.original?.point + props.row.original?.wallet_detail?.point
  //       : "ผิดพลาด",
  // },
  {
    Header: <div style={{ textAlign: "right" }}>Status</div>,
    accessor: "status",
    Cell: (props) => (
      <div style={{ textAlign: "right" }}>{RENDER_STATUS(props.value)}</div>
    ),
  },
];

export default withRouter(CUPointContainer);
