import styled from 'styled-components';

export const RewardSortStyled = styled.div`
  width: 100%;
  max-width: 800px;
  max-height: 80vh;
  overflow: auto;
  padding: 1rem;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .loading_wrap {
    display: flex;
    justify-content: center;
  }
  .rs_action_row {
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: flex-end;
    .rs_btn_wrap {
      width: 160px;
    }
  }
  .item_wrap {
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_1};
    padding: 0.25rem;
    display: flex;
    justify-content: space-between;
  }
`;
