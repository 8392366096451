import React, { useEffect } from 'react';
import { CampaignFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  CampaignType1Form,
  CampaignType2Form,
  CampaignType3Form,
} from './SubForms';
import { Inputs, Buttons } from 'components';

const CampaignForm = ({ onSubmit, onCancel, onDelete, initialValues }) => {
  const schema = yup.object().shape({
    code: yup.string().required('Campaign Code is required'),
    name: yup.string().required('Campaign Name is required'),
    type: yup.string().required('Campaign Type is required'),
    publish_type: yup.string().required('Who see? is required'),
    // img_path: initialValues.img_path
    //   ? [{ image_uri: initialValues.img_path }]
    //   : [],
  });

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const watchCampaignType = watch('type');

  useEffect(() => {
    if (initialValues) {
      reset({
        ...initialValues,
        start_date: new Date(initialValues.start_date),
        end_date: new Date(initialValues.end_date),
        front_img_file: initialValues.front_img
          ? [{ media_uri: initialValues.front_img }]
          : [],
        campaign_img_file: initialValues.campaign_img
          ? [{ media_uri: initialValues.campaign_img }]
          : [],
      });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const _handleDuplicate = (values) => {
    onSubmit({ ...values, isDuplicate: true });
  };

  return (
    <CampaignFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="body_form_campaign">
          <div className="input_type_wrap">
            <div className="itw_left_box">Campagin type</div>
            <div className="itw_right_box">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.Dropdown
                    {...field}
                    options={[
                      {
                        value: '1',
                        label: 'Simple Campaign',
                      },
                      {
                        value: '2',
                        label: 'Campaign with Box',
                      },
                      {
                        value: '3',
                        label: 'Campaign with picture',
                      },
                    ]}
                    errors={errors.type?.message}
                  />
                )}
                name="type"
                // defaultValue={{ label: '1', value: '1' }}
              />
            </div>
          </div>
          {watchCampaignType === '1' && (
            <CampaignType1Form control={control} errors={errors} />
          )}
          {watchCampaignType === '2' && (
            <CampaignType2Form control={control} errors={errors} />
          )}
          {watchCampaignType === '3' && (
            <CampaignType3Form control={control} errors={errors} />
          )}
          <div className="btn_zone">
            <div className="w120">
              <Buttons.BgStandard
                theme_gray
                label="Cancel"
                onClick={onCancel}
              />
            </div>
            {initialValues && (
              <div className="w120">
                <Buttons.BgStandard
                  theme_red
                  label="Delete"
                  onClick={onDelete}
                  disabled={!initialValues.is_edit}
                />
              </div>
            )}
            <div className="w120">
              <Buttons.BgStandard
                label="Duplicate"
                theme_green
                onClick={() => handleSubmit(_handleDuplicate)()}
                disabled={initialValues && !initialValues.is_edit}
              />
            </div>
            <div className="w120">
              <Buttons.BgStandard
                type="submit"
                label="Save"
                disabled={initialValues && !initialValues.is_edit}
              />
            </div>
          </div>
        </div>
      </form>
    </CampaignFormStyled>
  );
};

CampaignForm.propTypes = {};

export default CampaignForm;
