import React from 'react';
import { InformationContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { Forms, Inputs, Displays } from 'components';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';
import { portalService } from 'apiServices';
import { toast } from 'react-toastify';

class InformationContainer extends React.Component {
  state = { isLoading: true };

  componentDidMount() {
    scrollToTopPageFN();
    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
    }, 300);
  }

  handleFilterKeyword = async (e) => {
    this.setState({
      isLoading: true,
    });
    let res = await portalService.GET_CUSTOMER_BY_USER_ID(e.slice(9).trim());
    if (res && res.status === 200) {
      toast.success('พบข้อมูล');
      this.setState({
        initialValues: res.data,
        isLoading: false,
      });
    } else {
      toast.error('ไม่พบข้อมูล');
      this.setState({
        isLoading: false,
        initialValues: null,
      });
    }
  };

  handleSubmit = async (values) => {
    this.setState({
      isBgLoading: true,
    });
    let params = {
      user_id: values.user_id,
      cu_id: values.cu_id,
      firstname: values.firstname,
      lastname: values.lastname,
      phone_number: values.phone_number,
      email: values.email,
      address: values.address,
    };
    let res = await portalService.PUT_CUSTOMER_UPDATE_BY_ADMIN(
      values._id,
      params
    );
    if (res && res.status === 200) {
      toast.success('แก้ไขสำเร็จ');
      this.setState({
        isBgLoading: false,
      });
    } else {
      toast.error(
        res.errCode === 1 ? 'user id มีการใช้งานแล้ว' : 'แก้ไขไม่สำเร็จ'
      );
      this.setState({
        isBgLoading: false,
      });
    }
  };

  render() {
    const { isLoading, initialValues, isBgLoading } = this.state;
    return (
      <InformationContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        <div className="filter_layout">
          <Inputs.InputFilter
            theme_standard_input_filter
            dataFilter={dataFilter}
            btnLabel="ค้นหา"
            onQueryFilter={this.handleFilterKeyword}
            onExport={this.handleExport}
          />
        </div>
        {isLoading ? (
          <Displays.Loading theme_standard_loading heightLayout="334px" />
        ) : (
          <Forms.InformationForm
            initialValues={initialValues}
            onSubmit={this.handleSubmit}
          />
        )}
      </InformationContainerStyled>
    );
  }
}

const dataFilter = [
  {
    accessor: 'user_id',
    length: '311px',
    filter: {
      fieldQuery: 'user_id',
      type: 'text',
      label: 'Select User : ',
      placeholder: 'User ID',
    },
  },
];

export default withRouter(InformationContainer);
