import styled from 'styled-components';

export const StandardStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  table {
    border-spacing: 0;
    border: none;
    border-bottom: 0.0625rem solid #eeeeee;
    width: 100%;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
      &:hover {
        td {
          cursor: pointer;
          background: rgba(0, 0, 0, 0.05);
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.9375rem 0.625rem;
      border-bottom: 0.0625rem solid #eeeeee;
      :last-child {
        border-right: 0;
      }
    }
    .header_group {
      display: flex;
      column-gap: 5px;
    }
    th {
      position: relative;
      color: ${({ theme }) => theme.COLORS.BLUE_2};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_BOLD};
    }
    td {
      color: ${({ theme }) => theme.COLORS.BLUE_2};
      font-size: ${({ theme }) => theme.FONT.SIZE.S12};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
    }
    .sort {
      position: absolute;
      top: 14px;
      right: 10px;
    }
  }
  .pagi_layout {
    margin-top: 20px;
  }

  .table_action_row {
    display: flex;
    .ta_delet_btn {
      padding: 0.25rem 1rem;
      cursor: pointer;
    }
  }
`;
