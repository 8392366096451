import styled from 'styled-components';

export const BgIconPrefixStyled = styled.div`
  .btn {
    border-radius: 1rem;
    border: 1px solid ${({ theme }) => theme.COLORS.BLUE_4};
    padding: 0.625rem 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 3px;
    background: ${({ theme, disabled }) =>
      !disabled ? theme.COLORS.BLUE_4 : theme.COLORS.GRAY_4};
    color: ${({ theme, disabled }) =>
      disabled ? theme.COLORS.BLACK_2 : theme.COLORS.WHITE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_MEDIUM};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    &:hover {
      background: #165ac8;
      transition: 0.7s;
    }
  }

  .theme_stadard_btn {
  }
`;
