import React from 'react';
import { RewardGiftContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { portalService } from 'apiServices';
import { toast } from 'react-toastify';
import { Displays, Forms } from 'components';

class RewardGiftContainer extends React.Component {
  state = {};

  handleSubmit = async (values) => {
    const { id, onGiftSuccess } = this.props;
    this.setState({
      isLoadingBg: true,
    });
    let params = {
      ...values,
      reward_id: id,
    };
    let res = await portalService.POST_REDEEM_REWARD(params);
    if (res && res.status === 200) {
      this.setState({
        isLoadingBg: false,
      });
      toast.success('แจกสำเร็จ');
      onGiftSuccess && onGiftSuccess();
    } else {
      this.setState({
        isLoadingBg: false,
      });
      toast.error('ไม่สำเร็จ กรุณาตรวจสอบเงื่อนไข');
    }
  };

  render() {
    const { isLoading, isLoadingBg } = this.state;
    return (
      <RewardGiftContainerStyled>
        <Displays.BGLoading visible={isLoadingBg} />
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading />
          </div>
        ) : (
          <Forms.RewardGiftForm onSubmit={this.handleSubmit} />
        )}
      </RewardGiftContainerStyled>
    );
  }
}

export default withRouter(RewardGiftContainer);
