import styled from 'styled-components';

export const RegistrationFormStyled = styled.div`
  .input_wrap {
    margin-bottom: 0.75rem;
  }
  .btn_zone {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
    .w120 {
      width: 120px;
    }
  }
`;
