import React from 'react';
import { UploadedPointContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { Displays, Inputs, Tables } from 'components';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';
import { portalService } from 'apiServices';
import * as XLSX from 'xlsx';
import moment from 'moment';
import 'moment-timezone';
import { RenderCommaMoney } from 'utils/functions/FormatMoney';

class UploadedPointContainer extends React.Component {
  state = { isLoading: true, pagination: null, filterQuery: null, perPage: 10 };

  componentDidMount() {
    const { perPage } = this.state;
    scrollToTopPageFN();
    this.fetchData(`?limit=${perPage}&status=1`);
  }

  fetchData = async (stringQuery) => {
    let res = await portalService.GET_UPLOADED_POINT_LIST(stringQuery);
    if (res && res.status === 200) {
      this.setState({
        data: res.data.docs,
        isLoading: false,
        pagination: {
          last_page: res.data.totalPages,
          current_page: res.data.page,
        },
      });
    }
  };

  handleFilterKeyword = (e) => {
    const { perPage } = this.state;
    this.setState({
      filterQuery: e,
    });
    this.handleJoinQuery(e, perPage, 1);
  };

  handlePageClick = (e) => {
    const { filterQuery, perPage } = this.state;
    this.handleJoinQuery(filterQuery, perPage, e.selected + 1);
  };

  handleJoinQuery = (query, per_page, page) => {
    let joinQuery = query
      ? `${query}&limit=${per_page}&page=${page}&status=1`
      : `?limit=${per_page}&page=${page}&status=1`;
    this.setState({
      isLoading: true,
    });
    this.fetchData(joinQuery);
  };

  handleExport = async () => {
    const { filterQuery } = this.state;
    this.setState({
      isBgLoading: true,
    });
    let joinQuery = filterQuery
      ? `${filterQuery}&pagination=false&status=1`
      : `?pagination=false&status=1`;
    let res = await portalService.GET_UPLOADED_POINT_LIST(joinQuery);
    if (res && res.status === 200) {
      var wb = XLSX.utils.book_new();
      var ws_name = 'SheetJS';
      /* make worksheet */
      var ws_data = res.data.docs.reduce((result, e, i) => {
        result.push([
          i + 1,
          moment(e.updated_at).tz('Asia/Bangkok').format('DD/MM/YYYY'),
          e.customer_user_id,
          moment(e.created_at).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm:ss'),
          e.customer_cu_id,
          e.campaign_code,
          RenderCommaMoney(Number(e.so_amount), true),
          RenderCommaMoney(Number(e.point), true),
        ]);
        return result;
      }, []);
      ws_data.splice(0, 0, [
        'No',
        'Updated date',
        'Login ID',
        'Created at',
        'CU code',
        'Campaign code',
        'SO amount',
        'Point',
      ]);
      var ws = XLSX.utils.aoa_to_sheet(ws_data);
      var wscols = [
        { wch: 5 },
        { wch: 15 },
        { wch: 35 },
        { wch: 25 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];
      ws['!cols'] = wscols;
      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(
        wb,
        `Uploaded point report ${moment()
          .tz('Asia/Bangkok')
          .format('DD/MM/YYYY')}.xlsx`
      );

      this.setState({
        isBgLoading: false,
      });
    }
  };

  handleChangeOutputType = (e) => {
    this.setState({
      outputTypeCurrent: e,
    });
  };

  render() {
    const { isLoading, isBgLoading, pagination, data } = this.state;
    return (
      <UploadedPointContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        <div className="filter_layout">
          <Inputs.InputFilter
            theme_standard_input_filter
            dataFilter={dataFilter}
            btnLabel="ค้นหา"
            onQueryFilter={this.handleFilterKeyword}
            onExport={this.handleExport}
          />
        </div>
        {isLoading ? (
          <Displays.Loading theme_standard_loading heightLayout="418px" />
        ) : (
          <Tables.Standard
            columns={columns}
            data={data}
            showPagination
            pagination={pagination}
            handlePageClick={this.handlePageClick}
          />
        )}
      </UploadedPointContainerStyled>
    );
  }
}

const dataFilter = [
  {
    accessor: 'user_id',
    length: '311px',
    filter: {
      fieldQuery: 'user_id',
      type: 'text',
      label: 'Select User : ',
      placeholder: 'User ID',
    },
  },
  {
    accessor: 'date',
    filter: {
      fieldQuery: 'date',
      type: 'dateRank',
      label: 'Date Range : ',
    },
  },
];

const columns = [
  {
    Header: <div style={{ textAlign: 'left' }}>Updated date</div>,
    accessor: 'updated_at',
    Cell: (props) => (
      <div>{moment(props.value).tz('Asia/Bangkok').format('DD/MM/YYYY')}</div>
    ),
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Login ID</div>,
    accessor: 'customer_user_id',
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Created at</div>,
    accessor: 'created_at',
    Cell: (props) =>
      moment(props.value).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm:ss'),
  },
  {
    Header: <div style={{ textAlign: 'left' }}>CU code</div>,
    accessor: 'customer_cu_id',
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Campaign code</div>,
    accessor: 'campaign_code',
  },
  {
    Header: <div style={{ textAlign: 'left' }}>SO amount</div>,
    accessor: 'so_amount',
    Cell: (props) => RenderCommaMoney(Number(props.value), true),
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Point</div>,
    accessor: 'point',
    Cell: (props) => RenderCommaMoney(Number(props.value), true),
  },
];

export default withRouter(UploadedPointContainer);
