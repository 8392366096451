import apiService from "../apiService";

const apiPortalPath = "/api/v1";

export const portalService = {
  POST_LOGIN: (params) => {
    return apiService.post(`${apiPortalPath}/admin-login`, params);
  },
  GET_MY_PROFILE: () => {
    return apiService.get(`${apiPortalPath}/admin/myProfile`);
  },

  // CAMPAIGN
  GET_CAMPAIGN_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/campaign${stringQuery ? stringQuery : ""}`
    );
  },
  GET_CAMPAIGN_ALL_LIST: () => {
    return apiService.get(`${apiPortalPath}/campaign/all-campaign`);
  },
  GET_CAMPAIGN_BY_ID: (id) => {
    return apiService.get(`${apiPortalPath}/campaign/${id}`);
  },
  POST_CAMPAIGN: (params) => {
    return apiService.post(`${apiPortalPath}/campaign`, params);
  },
  PUT_CAMPAIGN: (id, params) => {
    return apiService.put(`${apiPortalPath}/campaign/${id}`, params);
  },
  DELETE_CAMPAIGN: (id) => {
    return apiService.delete(`${apiPortalPath}/campaign/${id}`);
  },
  POST_UPLOAD_CAMPAIGN: (params) => {
    return apiService.post(`${apiPortalPath}/campaign/upload`, params);
  },

  // CAMPAIGN HISTORY
  GET_CAMPAIGN_HISTORY_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/campaign-history${stringQuery ? stringQuery : ""}`
    );
  },

  // REWARD HISTORY
  GET_REWARD_HISTORY_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/reward-history${stringQuery ? stringQuery : ""}`
    );
  },

  // CUSTOMER
  GET_CUSTOMER_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/customer${stringQuery ? stringQuery : ""}`
    );
  },
  GET_CUSTOMER_ALL_LIST: () => {
    return apiService.get(`${apiPortalPath}/customer/all-customer`);
  },
  GET_CUSTOMER_BY_USER_ID: (id) => {
    return apiService.get(`${apiPortalPath}/customer/by-user-id/${id}`);
  },
  PUT_CUSTOMER_UPDATE_BY_ADMIN: (id, params) => {
    return apiService.put(
      `${apiPortalPath}/customer/update-by-admin/${id}`,
      params
    );
  },
  DELETE_CUSTOMER: (id) => {
    return apiService.delete(`${apiPortalPath}/customer/delete/${id}`);
  },

  // CONFIG
  GET_CONFIG_LIST: (id) => {
    return apiService.get(`${apiPortalPath}/config`);
  },
  GET_CONFIG_BY_ID: (id) => {
    return apiService.get(`${apiPortalPath}/config/${id}`);
  },
  POST_UPLOAD_CONFIG: (params) => {
    return apiService.post_formdata(`${apiPortalPath}/config/upload`, params);
  },
  POST_UPLOAD_DELETE: (params) => {
    return apiService.post(`${apiPortalPath}/config/delete-image`, params);
  },

  // REWARD
  GET_REWARD_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/reward${stringQuery ? stringQuery : ""}`
    );
  },
  GET_REWARD_BY_ID: (id) => {
    return apiService.get(`${apiPortalPath}/reward/${id}`);
  },
  POST_REWARD: (params) => {
    return apiService.post(`${apiPortalPath}/reward`, params);
  },
  PUT_REWARD: (id, params) => {
    return apiService.put(`${apiPortalPath}/reward/${id}`, params);
  },
  PUT_REWARD_SEQUENCE_UPDATE: (id, params) => {
    return apiService.put(`${apiPortalPath}/reward/sequence/${id}`, params);
  },
  DELETE_REWARD: (id) => {
    return apiService.delete(`${apiPortalPath}/reward/${id}`);
  },
  POST_UPLOAD_REWARD: (params) => {
    return apiService.post(`${apiPortalPath}/reward/upload`, params);
  },

  // REWARD REDEEM
  POST_REDEEM_REWARD: (params) => {
    return apiService.post(
      `${apiPortalPath}/reward-history/admin/redeem`,
      params
    );
  },

  // WALLET HISTORY
  GET_WALLET_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/wallet${stringQuery ? stringQuery : ""}`
    );
  },

  // WALLET HISTORY
  GET_WALLET_HISTORY_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/wallet-history${stringQuery ? stringQuery : ""}`
    );
  },

  // UPLOAD
  POST_IMPORT_REWARD: (params) => {
    return apiService.post_formdata(`${apiPortalPath}/reward/import`, params);
  },
  POST_IMPORT_POINT: (params) => {
    return apiService.post_formdata(
      `${apiPortalPath}/campaign-history/import`,
      params
    );
  },
  GET_UPLOADED_POINT_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/upload-point${stringQuery ? stringQuery : ""}`
    );
  },

  GET_CUSTOMER_UPLOADED_POINT_LIST: (id) => {
    return apiService.get(`${apiPortalPath}/upload-point/customer/${id}`);
  },

  // BANNER
  GET_BANNER_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPortalPath}/popup-banner${stringQuery ? stringQuery : ""}`
    );
  },
  GET_BANNER_DETAIL: (id) => {
    return apiService.get(`${apiPortalPath}/popup-banner/${id}`);
  },
  POST_BANNER_DETAIL: (params) => {
    return apiService.post(`${apiPortalPath}/popup-banner`, params);
  },
  POST_BANNER_UPLOAD: (params) => {
    return apiService.post_formdata(
      `${apiPortalPath}/popup-banner/upload`,
      params
    );
  },
  PUT_BANNER_UPDATE: (id, params) => {
    return apiService.put(`${apiPortalPath}/popup-banner/${id}`, params);
  },
  DELETE_BANNER: (id) => {
    return apiService.delete(`${apiPortalPath}/popup-banner/${id}`);
  },

  // WALLET HISTORY
  POST_WALLET_APPROVE: (params) => {
    return apiService.post(`${apiPortalPath}/wallet-history/approve`, params);
  },
};
