import styled from "styled-components";

export const UserPointHistoryContainerStyled = styled.div`
  width: 100%;
  max-width: 600px;
  max-height: 80vh;
  overflow: auto;
  padding: 1rem;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .loading_show {
    margin-top: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .phone_layout {
    position: relative;
    margin: auto;
    width: 492px;
    .phone_body_container {
      border-radius: 1rem;
      position: absolute;
      top: 16px;
      left: 0px;
      right: 0px;
      margin: auto;
      width: 312px;
      overflow: hidden;
      height: calc(100% - 83px);
      .p_header {
        position: absolute;
        top: 0px;
        width: 100%;
        height: 123px;
        background-color: ${({ theme }) => theme.COLORS.BLUE_1};
        background-image: url(/assets/images/bg/bg.png);
        background-position: center;
        background-size: cover;
        z-index: 3;
        .header_row {
          height: 100%;
          display: flex;
          justify-content: space-betweeen;
          align-items: center;
          .header_left_col {
            flex: 1;
            height: calc(100% - 30px);
            overflow: hidden;
            padding: 15px;
            .nav_logo_wrap {
              margin: auto;
              width: 100%;
              height: auto;
              .nav_logo {
                width: 100%;
                height: auto;
              }
            }
          }
          .header_right_col {
            flex-shrink: 0;
            flex-basis: 140px;
            .nav_point {
              margin-bottom: -24px;
              border-radius: 107px;
              border: 4px solid ${({ theme }) => theme.COLORS.WHITE_1};
              position: relative;
              width: 107px;
              height: 107px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #facc00;
              color: ${({ theme }) => theme.COLORS.BLACK_1};
              font-size: ${({ theme }) => theme.FONT.SIZE.S35};
              font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
              .nav_p_label {
                position: absolute;
                top: -8px;
                right: -18px;
                width: 80px;
                height: 25px;
                background-image: url(/assets/images/icons/point.png);
                background-size: contain;
                background-repeat: no-repeat;
                display: flex;
                justify-content: center;
                align-items: center;
                .nav_point_label {
                  color: ${({ theme }) => theme.COLORS.WHITE_1};
                  font-size: ${({ theme }) => theme.FONT.SIZE.S10};
                  font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
                }
              }
              .coin_img {
                position: absolute;
                bottom: 0px;
                right: -20px;
                width: 34px;
                height: auto;
              }
            }
          }
        }
      }
      .p_body {
        position: absolute;
        top: 123px;
        padding: 20px 14px 14px 14px;
        height: 441px;
        width: calc(100% - 24px);
        background-color: ${({ theme }) => theme.COLORS.BLUE_1};
        background-position: center;
        background-size: cover;
        background-image: url(/assets/images/bg/bg-white.jpg);
        overflow: auto;
      }
      .p_menu_bottom {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 75px;
        display: flex;
        background: #095098;
        background-position: center;
        background-size: cover;
        .menu_wrap {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &.active {
            .menu_item {
              .mi_label {
                color: ${({ theme }) => theme.COLORS.ORANGE_1};
              }
            }
          }
          .menu_item {
            padding-top: 6px;
            .menu_img_figture {
              margin: auto;
              width: 36px;
              height: 36px;
              .menu_img {
                width: 36px;
                height: 36px;
                object-fit: cover;
              }
            }
            .mi_label {
              margin-top: 6px;
              text-align: center;
              color: ${({ theme }) => theme.COLORS.WHITE_1};
              font-size: ${({ theme }) => theme.FONT.SIZE.S14};
              font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
            }
          }
        }
      }
    }
  }
`;
