import React from 'react';

export default function renderIcon({ width = 49, height = 49 }) {
  return (
    <svg
      xmlnssvg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.0"
      width={width}
      height={height}
      viewBox="0 0 128 128"
      xmlSpace="preserve"
    >
      <rect x="0" y="0" width="100%" height="100%" fill="transparent" />
      <g>
        <circle cx="16" cy="64" r="16" fill="#165ac8D9" />
        <circle
          cx="16"
          cy="64"
          r="16"
          fill="#165ac89C"
          transform="rotate(45,64,64)"
        />
        <circle
          cx="16"
          cy="64"
          r="16"
          fill="#165ac85C"
          transform="rotate(90,64,64)"
        />
        <circle
          cx="16"
          cy="64"
          r="16"
          fill="#dacce6"
          transform="rotate(135,64,64)"
        />
        <circle
          cx="16"
          cy="64"
          r="16"
          fill="#e9e1f0"
          transform="rotate(180,64,64)"
        />
        <circle
          cx="16"
          cy="64"
          r="16"
          fill="#e9e1f0"
          transform="rotate(225,64,64)"
        />
        <circle
          cx="16"
          cy="64"
          r="16"
          fill="#e9e1f0"
          transform="rotate(270,64,64)"
        />
        <circle
          cx="16"
          cy="64"
          r="16"
          fill="#e9e1f0"
          transform="rotate(315,64,64)"
        />
        <animateTransform
          attributeName="transform"
          type="rotate"
          values="45 64 64;90 64 64;135 64 64;180 64 64;225 64 64;270 64 64;315 64 64;0 64 64"
          calcMode="discrete"
          dur="800ms"
          repeatCount="indefinite"
        ></animateTransform>
      </g>
    </svg>
  );
}
