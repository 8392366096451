import Dropdown from './Dropdown';
import InputAutoSuggestion from './InputAutoSuggestion';
import InputCheckBox from './InputCheckBox';
import InputDatePickerBetween from './InputDatePickerBetween';
import Radio from './Radio';
import TextField from './TextField';
import UploadImage from './UploadImage';
import UploadSetImage from './UploadSetImage';
import InputFilter from './InputFilter';
import InputMultiSelect from './InputMultiSelect';
import InputSwitch from './InputSwitch';
import DateTime from './DateTime';
import TextArea from './TextArea';
import Color from './Color';

const EXPORT = {
  DateTime,
  InputFilter,
  InputMultiSelect,
  InputSwitch,
  TextField,
  InputCheckBox,
  Dropdown,
  InputAutoSuggestion,
  InputDatePickerBetween,
  Radio,
  UploadImage,
  Color,
  TextArea,
  UploadSetImage,
};

export default EXPORT;
