import React from 'react';
import { RewardGiftFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Buttons, Inputs } from 'components';
import { portalService } from 'apiServices';

const RewardGiftForm = ({ onSubmit }) => {
  const schema = yup.object().shape({
    customer_user_id: yup.string().required('User id is required'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <RewardGiftFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_wrap">
          <div className="w50">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputAutoSuggestion
                  {...field}
                  label="Select User :"
                  query="?user_id"
                  apiService={portalService.GET_CUSTOMER_LIST}
                  placeholder={'กรุณากรอก user id'}
                  errors={errors.customer_user_id?.message}
                />
              )}
              name="customer_user_id"
              defaultValue=""
            />
          </div>
        </div>

        <Buttons.BgStandard type="submit" label="Gift" />
      </form>
    </RewardGiftFormStyled>
  );
};

RewardGiftForm.propTypes = {};

export default RewardGiftForm;
