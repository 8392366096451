export const RENDER_PUBLISH = (e) => {
  switch (e) {
    case '1':
      return 'Public';
    case '2':
      return 'Private';
    default:
      break;
  }
};
