export const REWARD_RENDER_TYPE = (type) => {
  switch (type) {
    case '1':
      return 'E-coupon';
    case '2':
      return 'Coupon';
    case '3':
      return 'Other';
    default:
      break;
  }
};
