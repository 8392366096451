import React from 'react';
import { RegistrationContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { portalService } from 'apiServices';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';
import { toast } from 'react-toastify';
import { Forms, Displays } from 'components';

class RegistrationContainer extends React.Component {
  state = { isLoading: true };

  componentDidMount() {
    scrollToTopPageFN();
    this.fetchData();
  }

  fetchData = async () => {
    let res = await portalService.GET_CONFIG_LIST();
    if (res && res.status === 200) {
      this.setState({
        initialValues: res.data.docs.length > 0 ? { ...res.data.docs[0] } : {},
        isLoading: false,
      });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleSubmit = async (values) => {
    this.setState({
      isBgLoading: true,
    });
    let filesTemp = [
      ...values.see_user_manual_img.filter((e) => e.isNewUpload),
      ...values.see_cu_manual_img.filter((e) => e.isNewUpload),
    ];
    if (filesTemp && filesTemp.length > 0) {
      this.handleUploadContentMedia(filesTemp);
    } else {
      this.setState({
        isBgLoading: false,
      });
      toast.error('ไม่มีการอัพเดตใหม่');
    }
  };

  handleUploadContentMedia = (files) => {
    Promise.all(
      files.map((e) => {
        return new Promise(async (resolve) => {
          if (e.isNewUpload) {
            const bodyFormData = new FormData();
            bodyFormData.set('type', e.fieldName);
            bodyFormData.append('image', e);
            let res = await portalService.POST_UPLOAD_CONFIG(bodyFormData);
            if (res && res.status === 200) {
              resolve();
            }
          }
        });
      })
    ).then(() => {
      this.setState({
        isBgLoading: false,
      });
      toast.success('อัพเดจสำเร็จ');
      this.fetchData();
    });
  };

  render() {
    const { isLoading, isBgLoading, initialValues } = this.state;

    return (
      <RegistrationContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {isLoading ? (
          <Displays.Loading theme_standard_loading heightLayout="241px" />
        ) : (
          <Forms.RegistrationForm
            initialValues={initialValues}
            onSubmit={this.handleSubmit}
          />
        )}
      </RegistrationContainerStyled>
    );
  }
}

export default withRouter(RegistrationContainer);
