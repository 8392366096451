import styled from 'styled-components';

export const BannerFormStyled = styled.div`
  .body_form_campaign {
    .input_type_wrap {
      margin-bottom: 20px;
      display: flex;
      width: 100%;
      .itw_left_box {
        width: 20%;
        display: flex;
        align-items: center;
      }
      .itw_right_box {
        width: 80%;
        .w50 {
          width: 50%;
        }
        .group_obj {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .w40 {
            width: 40%;
          }
        }
      }
    }
    .btn_zone {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
      column-gap: 10px;
      .w120 {
        width: 120px;
      }
    }
  }
`;
