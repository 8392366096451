import styled from "styled-components";

export const CUPointContainerStyled = styled.div`
  .input_upload_wrap {
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
    .upload_block {
      max-width: 300px;
      width: 100%;
    }
    .btn_action_filter {
      height: 100%;
      width: 147px;
      flex-shrink: 0;
    }
  }
  .table_wrap {
    margin-bottom: 3.5rem;
    .tabale_title {
      padding-left: 0.75rem;
      color: ${({ theme }) => theme.COLORS.GRAY_6};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
    }
  }
  .btn_approve {
    margin-left: auto;
    margin-right: 0px;
    max-width: 180px;
  }
`;
