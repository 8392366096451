import React from 'react';
// import PropTypes from 'prop-types'
import { CampaignType1FormStyled } from './styled';
import { Controller } from 'react-hook-form';
import { Inputs } from 'components';
// import moment from 'moment';

const CampaignType1Form = ({ control, errors }) => {
  return (
    <CampaignType1FormStyled>
      <div className="input_warp">
        <div className="left_box">Who see?</div>
        <div className="right_box">
          <div className="w50">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.Dropdown
                  {...field}
                  options={[
                    {
                      value: '1',
                      label: 'Public',
                    },
                    {
                      value: '2',
                      label: 'Private',
                    },
                  ]}
                  errors={errors.publish_type?.message}
                />
              )}
              name="publish_type"
              // defaultValue={{ label: 'Public', value: '1' }}
            />
          </div>
        </div>
      </div>
      <div className="input_warp">
        <div className="left_box">Campaign code:</div>
        <div className="right_box">
          <div className="w50">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField {...field} errors={errors.code?.message} />
              )}
              name="code"
              defaultValue=""
            />
          </div>
        </div>
      </div>
      <div className="input_warp">
        <div className="left_box">Campaign name:</div>
        <div className="right_box">
          <div className="w50">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField {...field} errors={errors.name?.message} />
              )}
              name="name"
              defaultValue=""
            />
          </div>
        </div>
      </div>
      <div className="input_warp">
        <div className="left_box">Publish date:</div>
        <div className="right_box">
          <div className="group_obj">
            <div>From:</div>
            <div className="w40">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.DateTime
                    {...field}
                    placeholder={'วันเริ่ม'}
                    errors={errors.start_date?.message}
                    showYearDropdown
                  />
                )}
                name="start_date"
                defaultValue={new Date()}
              />
            </div>
            <div>To:</div>
            <div className="w40">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.DateTime
                    {...field}
                    placeholder={'วันสิ้นสุด'}
                    errors={errors.end_date?.message}
                    showYearDropdown
                  />
                )}
                name="end_date"
                defaultValue={new Date()}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="input_warp">
        <div className="left_box">Enable : </div>
        <div className="right_box">
          <div className="w50">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.InputSwitch
                  {...field}
                  errors={errors.is_active?.message}
                />
              )}
              name="is_active"
              defaultValue=""
            />
          </div>
        </div>
      </div>
      <div className="input_warp">
        <div className="left_box">Front picture:</div>
        <div className="right_box">
          <div className="w50">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.UploadImage
                  {...field}
                  theme_upload_image
                  fieldName="front_img"
                  scaleLabel="*** รูปขนาดกว้าง 397px สูง 190px"
                  errors={errors.front_img?.message}
                />
              )}
              name="front_img_file"
              defaultValue={[]}
            />
          </div>
        </div>
      </div>
      <div className="input_warp">
        <div className="left_box">Campaign picture :</div>
        <div className="right_box">
          <div className="w50">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.UploadImage
                  {...field}
                  theme_upload_image
                  fieldName="campaign_img"
                  scaleLabel="*** รูปขนาดกว้าง 397px สูงปล่อยยาว"
                  errors={errors.campaign_img?.message}
                />
              )}
              name="campaign_img_file"
              defaultValue={[]}
            />
          </div>
        </div>
      </div>
      <div className="input_warp mt30">
        <div className="left_box">Campaign rules:</div>
        <div className="right_box">
          <div className="w50">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextArea
                  {...field}
                  errors={errors.description?.message}
                />
              )}
              name="description"
              defaultValue=""
            />
          </div>
        </div>
      </div>
      <div className="input_warp">
        <div className="left_box">Join campaign button :</div>
        <div className="right_box">
          <div className="w50">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  errors={errors.btn_join_label?.message}
                />
              )}
              name="btn_join_label"
              defaultValue="ยืนยันเข้าร่วมกิจกรรม"
            />
          </div>
          <div className="color_zone">
            <div className="box_color">
              <div className="label">Text color:</div>
              <div>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.Color
                      {...field}
                      errors={errors.btn_join_text_color?.message}
                    />
                  )}
                  name="btn_join_text_color"
                  defaultValue=""
                />
              </div>
            </div>
            <div className="box_color">
              <div className="label">Button color:</div>
              <div>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.Color
                      {...field}
                      errors={errors.btn_join_button_color?.message}
                    />
                  )}
                  name="btn_join_button_color"
                  defaultValue=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="input_warp">
        <div className="left_box">Joinned campaign button :</div>
        <div className="right_box">
          <div className="w50">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  errors={errors.btn_joined_label?.message}
                />
              )}
              name="btn_joined_label"
              defaultValue="คุณเข้าร่วมร่วมกิจกรรมแล้ว"
            />
          </div>
          <div className="color_zone">
            <div className="box_color">
              <div className="label">Text color:</div>
              <div>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.Color
                      {...field}
                      errors={errors.btn_joined_text_color?.message}
                    />
                  )}
                  name="btn_joined_text_color"
                  defaultValue=""
                />
              </div>
            </div>
            <div className="box_color">
              <div className="label">Button color:</div>
              <div>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.Color
                      {...field}
                      errors={errors.btn_joined_button_color?.message}
                    />
                  )}
                  name="btn_joined_button_color"
                  defaultValue=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </CampaignType1FormStyled>
  );
};

CampaignType1Form.propTypes = {};

export default CampaignType1Form;
