import React, { useState, useEffect } from 'react';
import { UserInterfaceFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Inputs, Buttons } from 'components';

const UserInterfaceForm = ({ onSubmit, initialValues }) => {
  const schema = yup.object().shape({
    // test: yup.string().required('New password is required'),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (initialValues) {
      reset({
        header_img: initialValues.header_img
          ? [{ media_uri: initialValues.header_img }]
          : [],
        header_bg_img: initialValues.header_bg_img
          ? [{ media_uri: initialValues.header_bg_img }]
          : [],
        footer_bg_img: initialValues.footer_bg_img
          ? [{ media_uri: initialValues.footer_bg_img }]
          : [],
        bg_img: initialValues.bg_img
          ? [{ media_uri: initialValues.bg_img }]
          : [],
        menu1_img: initialValues.menu1_img
          ? [{ media_uri: initialValues.menu1_img }]
          : [],
        menu1_active_img: initialValues.menu1_active_img
          ? [{ media_uri: initialValues.menu1_active_img }]
          : [],
        menu2_img: initialValues.menu2_img
          ? [{ media_uri: initialValues.menu2_img }]
          : [],
        menu2_active_img: initialValues.menu2_active_img
          ? [{ media_uri: initialValues.menu2_active_img }]
          : [],
        menu3_img: initialValues.menu3_img
          ? [{ media_uri: initialValues.menu3_img }]
          : [],
        menu3_active_img: initialValues.menu3_active_img
          ? [{ media_uri: initialValues.menu3_active_img }]
          : [],
        menu4_img: initialValues.menu4_img
          ? [{ media_uri: initialValues.menu4_img }]
          : [],
        menu4_active_img: initialValues.menu4_active_img
          ? [{ media_uri: initialValues.menu4_active_img }]
          : [],
      });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const [_preview, _setPreview] = useState({});

  const _handlePreview = (key, e) => {
    let temp = _preview;
    if (e.filter((f) => !f.isDelete).length === 0) {
      delete temp[key];
      _setPreview(temp);
    } else {
      _setPreview({
        ..._preview,
        [key]:
          e.filter((f) => !f.isDelete).length === 0
            ? false
            : e.filter((f) => f.isNewUpload)[0].preview,
      });
    }
  };

  return (
    <UserInterfaceFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="body_form_user">
          <div className="input_warp">
            <div className="left_box">Header Logo:</div>
            <div className="right_box">
              <div className="w50">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.UploadImage
                      {...field}
                      theme_upload_image
                      isDelete
                      acceptType="png"
                      scaleLabel="*** รูปขนาดกว้าง 316px สูง 86px"
                      onChangeCustom={(e) => _handlePreview('header_img', e)}
                      errors={errors.header_img?.message}
                    />
                  )}
                  name="header_img"
                  defaultValue={[]}
                />
              </div>
            </div>
          </div>
          <div className="input_warp">
            <div className="left_box">Header Background:</div>
            <div className="right_box">
              <div className="w50">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.UploadImage
                      {...field}
                      theme_upload_image
                      isDelete
                      scaleLabel="*** รูปขนาดกว้างตามขนาดหน้าจอ สูง 123px"
                      onChangeCustom={(e) => _handlePreview('header_bg_img', e)}
                      errors={errors.header_bg_img?.message}
                    />
                  )}
                  name="header_bg_img"
                  defaultValue={[]}
                />
              </div>
            </div>
          </div>
          <div className="input_warp">
            <div className="left_box">Footer Background:</div>
            <div className="right_box">
              <div className="w50">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.UploadImage
                      {...field}
                      theme_upload_image
                      isDelete
                      scaleLabel="*** รูปขนาดกว้างตามขนาดหน้าจอ สูง 75px"
                      onChangeCustom={(e) => _handlePreview('footer_bg_img', e)}
                      errors={errors.footer_bg_img?.message}
                    />
                  )}
                  name="footer_bg_img"
                  defaultValue={[]}
                />
              </div>
            </div>
          </div>
          <div className="input_warp">
            <div className="left_box">Background:</div>
            <div className="right_box">
              <div className="w50">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.UploadImage
                      {...field}
                      theme_upload_image
                      isDelete
                      scaleLabel="*** รูปขนาดกว้าง 480px สูง 550px"
                      onChangeCustom={(e) => _handlePreview('bg_img', e)}
                      errors={errors.bg_img?.message}
                    />
                  )}
                  name="bg_img"
                  defaultValue={[]}
                />
              </div>
            </div>
          </div>
          <div className="input_warp mt30">
            <div className="left_box">Native bar:</div>
            <div className="right_box">
              <div className="box1 bg">Click</div>
              <div className="box2 bg">Unclick</div>
            </div>
          </div>
          <div className="input_warp">
            <div className="left_box">ICON picture no.1:</div>
            <div className="right_box">
              <div className="box1">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.UploadImage
                      {...field}
                      theme_upload_image
                      isDelete
                      acceptType="png"
                      scaleLabel="*** รูปขนาดกว้าง 36px สูง 36px"
                      onChangeCustom={(e) =>
                        _handlePreview('menu1_active_img', e)
                      }
                      errors={errors.menu1_active_img?.message}
                    />
                  )}
                  name="menu1_active_img"
                  defaultValue={[]}
                />
              </div>
              <div className="box2">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.UploadImage
                      {...field}
                      theme_upload_image
                      isDelete
                      acceptType="png"
                      scaleLabel="*** รูปขนาดกว้าง 36px สูง 36px"
                      onChangeCustom={(e) => _handlePreview('menu1_img', e)}
                      errors={errors.menu1_img?.message}
                    />
                  )}
                  name="menu1_img"
                  defaultValue={[]}
                />
              </div>
            </div>
          </div>
          <div className="input_warp">
            <div className="left_box">ICON picture no.2:</div>
            <div className="right_box">
              <div className="box1">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.UploadImage
                      {...field}
                      theme_upload_image
                      isDelete
                      acceptType="png"
                      scaleLabel="*** รูปขนาดกว้าง 36px สูง 36px"
                      onChangeCustom={(e) =>
                        _handlePreview('menu2_active_img', e)
                      }
                      errors={errors.menu2_active_img?.message}
                    />
                  )}
                  name="menu2_active_img"
                  defaultValue={[]}
                />
              </div>
              <div className="box2">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.UploadImage
                      {...field}
                      theme_upload_image
                      isDelete
                      acceptType="png"
                      scaleLabel="*** รูปขนาดกว้าง 36px สูง 36px"
                      onChangeCustom={(e) => _handlePreview('menu2_img', e)}
                      errors={errors.menu2_img?.message}
                    />
                  )}
                  name="menu2_img"
                  defaultValue={[]}
                />
              </div>
            </div>
          </div>
          <div className="input_warp">
            <div className="left_box">ICON picture no.3:</div>
            <div className="right_box">
              <div className="box1">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.UploadImage
                      {...field}
                      theme_upload_image
                      isDelete
                      acceptType="png"
                      scaleLabel="*** รูปขนาดกว้าง 36px สูง 36px"
                      onChangeCustom={(e) =>
                        _handlePreview('menu3_active_img', e)
                      }
                      errors={errors.menu3_active_img?.message}
                    />
                  )}
                  name="menu3_active_img"
                  defaultValue={[]}
                />
              </div>
              <div className="box2">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.UploadImage
                      {...field}
                      theme_upload_image
                      isDelete
                      acceptType="png"
                      scaleLabel="*** รูปขนาดกว้าง 36px สูง 36px"
                      onChangeCustom={(e) => _handlePreview('menu3_img', e)}
                      errors={errors.menu3_img?.message}
                    />
                  )}
                  name="menu3_img"
                  defaultValue={[]}
                />
              </div>
            </div>
          </div>
          <div className="input_warp">
            <div className="left_box">ICON picture no.4:</div>
            <div className="right_box">
              <div className="box1">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.UploadImage
                      {...field}
                      theme_upload_image
                      isDelete
                      acceptType="png"
                      scaleLabel="*** รูปขนาดกว้าง 36px สูง 36px"
                      onChangeCustom={(e) =>
                        _handlePreview('menu4_active_img', e)
                      }
                      errors={errors.menu4_active_img?.message}
                    />
                  )}
                  name="menu4_active_img"
                  defaultValue={[]}
                />
              </div>
              <div className="box2">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.UploadImage
                      {...field}
                      theme_upload_image
                      isDelete
                      acceptType="png"
                      scaleLabel="*** รูปขนาดกว้าง 36px สูง 36px"
                      onChangeCustom={(e) => _handlePreview('menu4_img', e)}
                      errors={errors.menu4_img?.message}
                    />
                  )}
                  name="menu4_img"
                  defaultValue={[]}
                />
              </div>
            </div>
          </div>
          <div className="btn_zone">
            {/* <div className='w120'>
              <Buttons.BgStandard theme_gray label="Cancel" onClick={onCancel} />
            </div> */}
            <div className="w120">
              <Buttons.BgStandard type="submit" label="Save" />
            </div>
          </div>
        </div>
      </form>
      <div className="phone_layout">
        <img alt="phone" src="/assets/images/icons/phone.png" />
        <div className="phone_body_container">
          <div
            className="p_header"
            style={
              _preview.header_bg_img && {
                backgroundImage: `url(${_preview.header_bg_img})`,
              }
            }
          >
            <div className="header_row">
              <div className="header_left_col">
                <div className="nav_logo_wrap">
                  {_preview.header_img && (
                    <img
                      className="nav_logo"
                      alt="misumi logo"
                      src={_preview.header_img}
                    />
                  )}
                </div>
              </div>
              <div className="header_right_col">
                <div className="nav_point">
                  <div className="nav_p_label">
                    <div className="nav_point_label">คะแนนของคุณ</div>
                  </div>
                  0
                  <img
                    className="coin_img"
                    alt="coin"
                    src="/assets/images/icons/coin.png"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="p_body"
            style={
              _preview.bg_img && { backgroundImage: `url(${_preview.bg_img})` }
            }
          ></div>
          <div
            className="p_menu_bottom"
            style={
              _preview.footer_bg_img && {
                backgroundImage: `url(${_preview.footer_bg_img})`,
              }
            }
          >
            <div className={`menu_wrap`}>
              <div className="menu_item">
                <div className="menu_img_figture">
                  {_preview.menu1_img && (
                    <img
                      className="menu_img"
                      alt="menu"
                      src={_preview.menu1_img}
                    />
                  )}
                </div>
                <div className="mi_label">กิจกรรม</div>
              </div>
            </div>
            <div className={`menu_wrap`}>
              <div className="menu_item">
                <div className="menu_img_figture">
                  {_preview.menu2_img && (
                    <img
                      className="menu_img"
                      alt="menu"
                      src={_preview.menu2_img}
                    />
                  )}
                </div>
                <div className="mi_label">ของรางวัล</div>
              </div>
            </div>
            <div className={`menu_wrap`}>
              <div className="menu_item">
                <div className="menu_img_figture">
                  {_preview.menu3_img && (
                    <img
                      className="menu_img"
                      alt="menu"
                      src={_preview.menu3_img}
                    />
                  )}
                </div>
                <div className="mi_label">ประวัติ</div>
              </div>
            </div>
            <div className={`menu_wrap`}>
              <div className="menu_item">
                <div className="menu_img_figture">
                  {_preview.menu4_img && (
                    <img
                      className="menu_img"
                      alt="menu"
                      src={_preview.menu4_img}
                    />
                  )}
                </div>
                <div className="mi_label">โปรไฟล์</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserInterfaceFormStyled>
  );
};

UserInterfaceForm.propTypes = {};

export default UserInterfaceForm;
