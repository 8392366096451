import styled from 'styled-components';

export const CampaignContainerStyled = styled.div`
  .btn_create {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    .w120 {
      width: 120px;
    }
  }
  .body_table {
    margin-top: 20px;
    .body_status {
      display: flex;
      justify-content: center;
      .round {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        flex-shrink: 0;
      }
    }
  }
`;
